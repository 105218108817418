import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "../api/api";
import useUrlQuery from "../hooks/useUrlQuery";
import { useGetProposalDetailsQuery } from "./Proposal.service";
interface PanCKYCReq {
  pan_no: string | undefined;
  PROPOSAL_ID?: string;
  dob?: string;
  INSURANCE_SLUG?: string;
}
export const useVerifyPanCKYC = () => {
  const { product } = useGetProposalDetailsQuery();
  const queryClient = useQueryClient();
  const { getUrlQuery } = useUrlQuery();
  const proposal_id = getUrlQuery("proposal_id");
  const { data, mutate, mutateAsync, isLoading, reset } = useMutation(
    (data: PanCKYCReq) => {
      return api.post(
        data?.INSURANCE_SLUG === "star"
          ? "api/VerifyStarCKYC"
          : "api/GetHdfcCKYCDetails",
        data
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["proposal", proposal_id]);
      },
    }
  );

  return {
    verifyCKYC: mutateAsync,
    isVerifyCKYC: isLoading,
    resetVerify: reset,
  };
};

// Define the types for the response as described earlier
interface CKYCResponseData {
  verification_status: boolean;
  ckycNumber: number;
  ckycReferenceId: string;
  proposer_address_proof: string;
  proposer_photo: string;
  proposer_form_60: string;
  status: number;
}

interface APIResponse {
  message: string;
  status: number;
  return_data: CKYCResponseData;
}

export const useGenerateCKYC = () => {
  const { data, mutate, mutateAsync, isLoading } = useMutation((data: any) => {
    return api.post("api/GenerateStarCKYC", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  });

  return {
    generateCKYC: mutateAsync,
    isGeneratingCKYC: isLoading,
    generateCkycData: data,
  };
};

export const useUploadCignaDocMutation = () => {
  const { product } = useGetProposalDetailsQuery();
  const { getUrlQuery } = useUrlQuery();
  const proposal_id = getUrlQuery("proposal_id");
  const { mutateAsync } = useMutation((data: any) => {
    return api.post(
      product?.INSURANCE_SLUG?.includes("care")
        ? "api/UploadCareCKYCDoc"
        : "api/UploadCignaCKYCDoc",
      { ...data, proposal_id },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  });
  return {
    upload: mutateAsync,
  };
};

export const useVerifyCareCKYC = () => {
  const queryClient = useQueryClient();
  const { getUrlQuery } = useUrlQuery();
  const proposal_id = getUrlQuery("proposal_id");
  const { mutateAsync, isLoading, reset, data } = useMutation(
    (data: PanCKYCReq) => {
      return api.post("api/VerifyCareCKYC", {
        ...data,
        proposal_id,
      });
    },
    {
      onSuccess: () => {
        // queryClient.invalidateQueries(["proposal", proposal_id]);
      },
    }
  );

  return {
    verifyCareCKYC: mutateAsync,
    isVerifyCareCKYC: isLoading,
    resetVerify: reset,
    ckycData: data?.data,
  };
};
