import {
  createTheme,
  InputLabel,
  TextField,
  ThemeProvider,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useField, useFormikContext } from "formik";
import moment, { Moment } from "moment";
import React from "react";

interface DateCompProps {
  label: string;
  name: string;
  error?: boolean;
  helperText?: string;
  minDate?: Moment;
  maxDate?: Moment;
  readOnly?: boolean;
  defaultValue?: Moment | string | null;
  VIEWS?: Array<"year" | "month" | "day">;
  touched?: boolean;
  required?: boolean;
}

const DateComp: React.FC<DateCompProps> = ({
  label,
  name,
  defaultValue = null,
  required = false,
  ...props
}) => {
  const [field, meta] = useField(name);
  const { setFieldValue, submitCount } = useFormikContext();
  const config = {
    ...field,
    ...props,
    onChange: (value: Moment | null) => {
      setFieldValue(name, moment(value).format("YYYY-MM-DD"));
    },
    value: field.value ? moment(field.value, "YYYY-MM-DD") : defaultValue,
  };

  if (meta && (meta.touched || submitCount) && meta.error) {
    config.error = true;
    config.helperText = meta.error;
  }

  const prevTheme = useTheme();
  const theme = createTheme({
    palette: prevTheme.palette,
  });

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={theme}>
          <DatePicker
            {...config}
            inputFormat="DD/MM/YYYY"
            openTo="year"
            readOnly={props.readOnly}
            views={props?.VIEWS ? props.VIEWS : ["year", "month", "day"]}
            renderInput={(params) => (
              <Box>
                <InputLabel
                  sx={{
                    fontSize: "15px",
                    mb: 1,
                  }}
                >
                  {label}
                  {required && (
                    <span style={{ color: "red", fontWeight: "bold" }}>
                      {" *"}
                    </span>
                  )}
                </InputLabel>
                <TextField
                  fullWidth
                  sx={{
                    "& input": {
                      color: "#2D2C5F",
                      fontSize: "16px",
                      pt: "12px",
                      textTransform: "uppercase",
                    },
                    "& fieldset": {
                      height: "50px",
                    },
                  }}
                  {...params}
                  error={config.error}
                  helperText={config.helperText}
                />
              </Box>
            )}
          />
        </ThemeProvider>
      </LocalizationProvider>
    </>
  );
};

export default DateComp;
