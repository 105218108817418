import { useQueries, useQuery, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import api from "../api/api";
import { useTypedSelector } from "../hooks/useTypedSelector";
import useUrlQuery from "../hooks/useUrlQuery";
import { addOrRemoveRider, setSelectedRiders } from "../modules/quotes.slice";
import { useGetHealthProductByIdQuery } from "./Landing.service";
import { useGetProposalDetailsQuery } from "./Proposal.service";
import Swal from "sweetalert2";

export interface GetQuotesResponseInterface {
  status: 200 | 500;
  PREMIUM_DATA: {
    PREMIUM_DATA: {
      NET_PREMIUM: string;
      TOTAL_PREMIUM: string;
      COVER_AMOUNT: string;
      BLOCK_PORTING: any;
      PORTING_MSG: string;
    };
  };
  PRODUCT_DATA: {
    PRODUCTID: number;
    IC_ID: number;
    ADULT_MIN: number;
    ADULT_MAX: number;
    LOB: string;
    POLICY_TYPE: string;
    PRODUCT_TYPE: string;
    PRODUCT_NAME: string;
    PRODUCT_IDENTIFIER: string;
    CREATED_DATE: string;
    UPDATED_DATE: string;
    INSURANCE_NAME: string;
    INSURANCE_SLUG: string;
    ADDRESS: string;
    CONTACT_DETAILS: string;
    CLAIM_FORM_LINK: string;
    BROCHURE_LINK: string;
    LOGO_PATH: string;
    CLAIM_SETTLEMENT_RATIO: string;
    PRIORITY: number;
  };
  PRODUCT_FEATURES: {
    ID: number;
    FEATURE_ID: number;
    PRODUCT_ID: number;
    FEATURE_VAL: string;
    CREATED_AT: string;
    HIGHLIGHT: number;
    USP_TEXT: string;
    FEATURE_TITLE: string;
    FEATURE_DESCRIPTION: string;
  }[];
}
export const useGetQuotes = () => {
  const { quote_end_points } = useGetHealthProductByIdQuery();
  const { filters } = useTypedSelector((state) => state.quote);
  const { preferred_insurer } = filters;
  const isInsurerFilterActive = preferred_insurer.length > 0;
  const quote_end_points_array = Object.keys(quote_end_points)
    .map((key) =>
      quote_end_points[key].map((endpoint: string) => ({
        alias: key,
        endpoint,
      }))
    )
    .flat() as { alias: string; endpoint: string }[];

  const first_batch = Object.keys(quote_end_points)
    .map((key) => quote_end_points[key][0])
    .flat();

  const firstBatchQueries = first_batch.map((item) => ({
    queryKey: ["quote", item],
    queryFn: () => {
      return api.get<GetQuotesResponseInterface>(item);
    },
    placeholderData: {
      data: {
        status: 200,
        PREMIUM_DATA: {
          PREMIUM_DATA: {
            TOTAL_PREMIUM: "0",
            COVER_AMOUNT: "0",
          },
        },
        PRODUCT_DATA: {
          INSURANCE_SLUG: item.alias,
        },
      } as GetQuotesResponseInterface,
    },
  }));
  const firstBatchResults = useQueries({
    // @ts-ignore
    queries: firstBatchQueries,
  });
  const isAllFetched = firstBatchResults
    .map((item) => item.isFetched)
    .every((item) => item === true);
  console.log({
    isAllFetched,
    data: firstBatchResults.map((item) => item.data),
    first_batch,
  });
  const queries = quote_end_points_array.map((item) => ({
    queryKey: ["quote", item.endpoint],
    queryFn: () => {
      return api.get<GetQuotesResponseInterface>(item.endpoint);
    },
    enabled:
      first_batch.some((batch) => batch.endpoint === item.endpoint) ||
      isAllFetched,
    placeholderData: {
      data: {
        status: 200,
        PREMIUM_DATA: {
          PREMIUM_DATA: {
            TOTAL_PREMIUM: "0",
            COVER_AMOUNT: "0",
          },
        },
        PRODUCT_DATA: {
          INSURANCE_SLUG: item.alias,
        },
      } as GetQuotesResponseInterface,
    },
  }));

  const results = useQueries({
    // @ts-ignore
    queries: queries,
  });
  const fetchedQuotes = results
    .filter(
      (result) =>
        result?.data?.data?.PREMIUM_DATA &&
        result.data?.data?.status !== 500 &&
        !result.error &&
        !result.isPlaceholderData
    )
    .map((result) => ({
      quoteData: result.data?.data,
      isLoading: result.isFetching,
      isPaused: result.isPaused || result.isPlaceholderData,
    }))
    .sort((a, b) => {
      if (a.isLoading && !b.isPaused) {
        return 1;
      } else if (b.isLoading && !a.isPaused) {
        return -1;
      }
      return (
        Number(a.quoteData?.PRODUCT_DATA?.PRIORITY) -
        Number(b.quoteData?.PRODUCT_DATA?.PRIORITY)
      );
    });
  const uniqueSortedQuotesSet = new Set(
    fetchedQuotes
      .sort((a, b) => {
        if (a.isLoading) {
          return 1;
        } else if (b.isLoading) {
          return -1;
        }
        return (
          Number(a.quoteData?.PRODUCT_DATA?.PRIORITY) -
          Number(b.quoteData?.PRODUCT_DATA?.PRIORITY)
        );
      })
      .map((item) => item.quoteData?.PRODUCT_DATA.INSURANCE_SLUG)
  );
  const uniqueSortedQuotesKeys = Array.from(uniqueSortedQuotesSet);
  const companiesWithLogos = fetchedQuotes.reduce((acc, item) => {
    if (item.quoteData?.PRODUCT_DATA.INSURANCE_SLUG) {
      acc[item.quoteData?.PRODUCT_DATA.INSURANCE_SLUG] = {
        logo: item.quoteData?.PRODUCT_DATA.LOGO_PATH,
        name: item.quoteData?.PRODUCT_DATA.INSURANCE_NAME,
        value: item.quoteData?.PRODUCT_DATA.INSURANCE_SLUG,
      };
    }
    return acc;
  }, {} as { [key: string]: { logo: string; name: string; value: string } });

  const totalQuotes = fetchedQuotes.length;
  const progress =
    totalQuotes - fetchedQuotes.filter((quote) => quote.isLoading).length;

  const progressPercentage = (progress / totalQuotes) * 100;
  return {
    fetchedQuotes,
    groupedQuotes: _.groupBy(
      fetchedQuotes.filter((quote) => {
        if (isInsurerFilterActive) {
          return preferred_insurer.includes(
            quote.quoteData?.PRODUCT_DATA?.INSURANCE_SLUG || ""
          );
        }
        return true;
      }),
      (item) =>
        item.quoteData?.PRODUCT_DATA?.INSURANCE_SLUG?.split("_")
          .slice(0, 2)
          .join("_")
    ),
    quoteLength: fetchedQuotes.filter((item) => !item.isLoading).length,

    progressPercentage,
    companiesWithLogos,
  };
};

export const useGetMultiYearQuotes = () => {
  const { quote_data, isProposalLoading, product } =
    useGetProposalDetailsQuery();
  const { getUrlQuery } = useUrlQuery();

  const proposal_id = getUrlQuery("proposal_id");
  const { data, isLoading } = useQuery(
    [
      "multiyear",
      quote_data?.COMPANY_SLUG,
      quote_data?.SUM_INSURED,
      product?.IC_ID,
      proposal_id,
    ],
    () =>
      api.get(
        `api/GetMultiYearHealthQuotes/${quote_data?.COMPANY_SLUG}/${quote_data?.PRODUCT_ID}/${quote_data?.QUOTE_ID}/${quote_data?.SUM_INSURED}/0`
      ),
    {
      enabled: !isProposalLoading,
    }
  );
  return {
    quote_data,
    discounts: [
      ...Object.values(data?.data.PREMIUM_DATA || { 0: 0, 1: 1, 2: 2 }),
    ],
    isAnyDiscounts:
      data?.data.PREMIUM_DATA && Object.values(data?.data.PREMIUM_DATA).length,
    isDiscountsLoading: isLoading,
  };
};
export interface RiderInterface {
  addon_name: string;
  premium: string;
  ADD_ON_ID: number;
  PRODUCT_ID: number;
  ADD_ON_NAME: string;
  ADD_ON_SLUG: string;
  IS_SI_REQUIRED: "Y" | "N";
  ALLOWED_SI: "string" | null;
  IS_MANDETORY: "Y" | "N";
  IS_MEMBER_LEVEL: "Y" | "N";
  SUM_INSURED: string;
  VALUE: string;
  DISABLE_ADDON: string;
  selectedMembers: string[];
  ENABLE_ADDON?: string;
  CHECK_ENABLED?: boolean;
  DISABLE_ADDON_SLUG: string;
  ADDON_DESCRIPTION: string;
  DROPDOWN_TITLE: string;
  additionalOptions: any;
}
interface GetRidersInterface {
  status: 200 | 500;
  data: {
    PORTING_MSG: any;
    ADDON_DATA: RiderInterface[];
    PREMIUM_DATA?: GetQuotesResponseInterface["PREMIUM_DATA"]["PREMIUM_DATA"];
  };
  showpopup?: boolean;
  message?: string;
}
//@ts-ignore
export const useGetRiders = (selectedMembers) => {
  const { getUrlQuery } = useUrlQuery();
  const { quote_data, isProposalLoading } = useGetProposalDetailsQuery();
  const proposal_id = getUrlQuery("proposal_id");
  const selectedRiders = useTypedSelector(
    (state) => state.quote.selectedRiders
  );
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { selectedTenure } = useTypedSelector((state) => state.quote);
  const port_flag = quote_data?.ISPORTING;

  const { data, isLoading, refetch, isFetching } = useQuery(
    [
      "riders",
      proposal_id,
      selectedTenure,
      quote_data?.SUM_INSURED,
      JSON.stringify(selectedMembers),
      port_flag,
    ],
    () =>
      api.post<GetRidersInterface>("api/GetAddonDetails", {
        PROPOSAL_ID: proposal_id,
        TENURE: selectedTenure,
        SUM_INSURED: quote_data?.SUM_INSURED,
        SELECTED_ADDONS: selectedRiders,
        selectedMembers,
        port_flag,
      }),
    {
      enabled: !isProposalLoading,
      onSuccess: (data) => {
        const mandatoryAddons =
          data?.data?.data?.ADDON_DATA?.filter(
            (item) => item.IS_MANDETORY === "Y"
          ) || [];
        const selectedNonMandatoryAddons = selectedRiders.filter(
          (item) => item.IS_MANDETORY === "N"
        );
        dispatch(
          setSelectedRiders([...mandatoryAddons, ...selectedNonMandatoryAddons])
        );
        if (data?.data?.showpopup) {
          Swal.fire("Alert", data?.data?.message);
        }
      },
    }
  );

  useEffect(() => {
    data?.data?.data?.ADDON_DATA?.forEach((item) => {
      if (
        item.IS_MANDETORY === "Y" &&
        !selectedRiders.some((rider) => rider.ADD_ON_ID === item.ADD_ON_ID)
      ) {
        dispatch(addOrRemoveRider(item));
      }
    });
    const newRiders = data?.data?.data?.ADDON_DATA || [];
    if (newRiders.length && selectedRiders.length) {
      const ridersWithUpdatedPremium = selectedRiders.map((rider) => {
        const newRider = newRiders.find(
          (newRider) => newRider.ADD_ON_ID === rider.ADD_ON_ID
        );
        if (newRider) {
          return {
            ...rider,
            premium: newRider.premium,
          };
        }
        return rider;
      });

      dispatch(setSelectedRiders(ridersWithUpdatedPremium));
    }
  }, [data?.data?.data?.ADDON_DATA]);

  useEffect(() => {
    if (data?.data?.data?.PREMIUM_DATA) {
      queryClient.setQueryData(["proposal", proposal_id], (old_data: any) => {
        return {
          ...old_data,
          data: {
            ...old_data?.data,
            data: {
              ...old_data?.data?.data,
              PROPOSAL: {
                ...old_data?.data?.data?.PROPOSAL,
                TOTAL_PREMIUM: data?.data?.data?.PREMIUM_DATA?.TOTAL_PREMIUM,
                NET_PREMIUM: data?.data?.data?.PREMIUM_DATA?.TOTAL_PREMIUM,
                BLOCK_PORTING: quote_data?.ISPORTING,
              },
            },
          },
        };
      });
    }
  }, [data?.data?.data?.PREMIUM_DATA]);

  const premium = useMemo(() => {
    return data?.data?.data?.ADDON_DATA?.map((item) => item.premium) || [];
  }, [data]);

  let riders_to_disable: any = [];
  let riders_to_enable: any = [];
  let riders_to_disable_slug: any = [];
  data?.data?.data?.ADDON_DATA?.forEach((item) => {
    if (
      item.DISABLE_ADDON &&
      selectedRiders.some((rider) => rider.ADD_ON_ID === item.ADD_ON_ID)
    ) {
      riders_to_disable = [
        ...riders_to_disable,
        ...String(item.DISABLE_ADDON).split(","),
      ];
    }
    if (
      item.DISABLE_ADDON_SLUG &&
      selectedRiders.some((rider) => rider.ADD_ON_SLUG === item.ADD_ON_SLUG)
    ) {
      riders_to_disable_slug = [
        ...riders_to_disable_slug,
        String(item.DISABLE_ADDON_SLUG),
      ];
    }
    if (
      item.ENABLE_ADDON &&
      selectedRiders.some((rider) => rider.ADD_ON_ID === item.ADD_ON_ID)
    ) {
      riders_to_enable = [
        ...riders_to_enable,
        ...String(item.ENABLE_ADDON).split(","),
      ];
    }
  });

  return {
    riders: data?.data?.data?.ADDON_DATA || [],
    riders_to_disable,
    isRidersLoading: isLoading,
    refetchRiders: refetch,
    isFetching,
    riders_to_enable,
    riders_to_disable_slug,
    port_flag,
    data: data,
  };
};

export interface IFeature {
  FEATURE_ID: number;
  CATEGORY_ID: number;
  FEATURE_TITLE: string;
  FEATURE_DESCRIPTION: string;
  CREATED_AT: string;
  ID: number;
  PRODUCT_ID: number;
  FEATURE_VAL: string;
  HIGHLIGHT: number;
  USP_TEXT: null;
  TYPE: string;
}
export const useGetViewPlanDetails = (product_id: number | undefined) => {
  const { data, isLoading } = useQuery({
    queryKey: ["view_plan", product_id],
    queryFn: () =>
      api.get(`api/GetHealthProductFeature?product_id=${product_id}`),
    enabled: !!product_id,
  });

  return {
    viewPlanDetails: data?.data,
    highlights: data?.data?.data?.highlights as IFeature[] | undefined,
    value_added_services: data?.data?.data?.[3]?.features as
      | IFeature[]
      | undefined,
    additionalFeatures: data?.data?.data?.[2]?.features as
      | IFeature[]
      | undefined,
    waiting_periods: data?.data?.data?.[1]?.features as IFeature[] | undefined,
    coverage: data?.data?.data?.[0]?.features as IFeature[] | undefined,
    documents: data?.data?.data?.[4]?.features as IFeature[] | undefined,
    isPlanDetailsLoading: isLoading,
  };
};

export const useGetQuote = (endpoint: string) => {
  const { data, isLoading } = useQuery(
    ["quote", endpoint],
    () => {
      return api.get<GetQuotesResponseInterface>(endpoint);
    },
    {
      enabled: !!endpoint,
    }
  );
  return {
    product_data: data?.data?.PRODUCT_DATA,
    premium_data: data?.data?.PREMIUM_DATA?.PREMIUM_DATA,
  };
};
