import { MembersInterface } from "../modules/landing.slice";

export const getMembers = (members: MembersInterface[]) => {
  let relation = "";
  if (members.some((member) => member.relation === "self")) {
    relation += "Self ";
  }
  if (members.some((member) => member.relation === "spouse")) {
    relation += "Spouse ";
  }
  if (members.some((member)=>member.relation === "father")){
    relation += "Father "
  }
  if (members.some((member)=>member.relation === "mother")){
    relation += "Mother "
  }
  if (
    members.some(
      (member) => member.relation === "son" || member.relation === "daughter"
    )
  ) {
    relation += "& Kids";
  }

  return relation;
};
