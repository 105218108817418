import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import api from "../api/api";
import useUrlQuery from "./../hooks/useUrlQuery";
import {
  MembersInterface,
  setMemberDetails,
  setPersonalDetails,
} from "../modules/landing.slice";
import { useDispatch } from "react-redux";
import { setFilters } from "../modules/quotes.slice";
interface GetProductsRequestInterface {
  pincode?: string;
  requested_si?: string;
  policy_type?: "I" | "F";
  mobile_no?: string;
  lob?: "HEALTH";
  members?: MembersInterface[];
  proposer_gender?: "male" | "female";
  existing_health_plan?: string;
  disease_details?: number[];
}

interface GetProductsResponseInterface {
  status: 200 | 500;
  response: {
    quote_id: string;
    quote_end_points: string[];
    eligible_products_count: number;
  };
  request: {
    pincode: string;
    mobile_no: string;
    members: MembersInterface[];
    lob: "HEALTH";
    policy_type: "I" | "F";
    requested_si: string;
    deductible: string;
    proposer_gender: "male" | "female";
    disease_details: number[];
    plan_type: string;
  };
}
export const useGetHealthProductMutation = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { getUrlQuery } = useUrlQuery();
  const hash = getUrlQuery("hash");
  const { mutate } = useMutation(
    (req: GetProductsRequestInterface) => {
      return api.post<GetProductsResponseInterface>(
        "api/GetHealthEligibleProducts",
        { ...req, hash }
      );
    },
    {
      onSuccess: (res) => {
        queryClient.setQueryData(["enquiry", res.data.response.quote_id], res);
        dispatch(
          setFilters({
            key: "requested_si",
            value: res.data.request.requested_si,
          })
        );
        navigate({
          pathname: "/quote",
          search: `?quote_id=${res.data.response.quote_id}`,
        });
      },
    }
  );

  return { getProducts: mutate };
};
export const useGetHealthProductByIdQuery = () => {
  const { getUrlQuery } = useUrlQuery();
  const quote_id = getUrlQuery("quote_id");
  const dispatch = useDispatch();
  const { data, isLoading } = useQuery(
    ["enquiry", quote_id],
    () => {
      return api.get<GetProductsResponseInterface>(
        `api/GetHealthEligibleProductsById/${quote_id}`
      );
    },
    {
      onSuccess: (res) => {
        dispatch(setMemberDetails(res.data.request.members));
        dispatch(
          setFilters({
            key: "requested_si",
            value: res.data.request.requested_si,
          })
        );
        dispatch(
          setFilters({
            key: "deductible",
            value: res.data.request.deductible || "0",
          })
        );
        dispatch(
          setFilters({
            key: "plan_type",
            value: res.data.request.plan_type || "50000",
          })
        );
        dispatch(
          setPersonalDetails({
            pincode: res.data.request.pincode,
            mobile_no: res.data.request.mobile_no,
          })
        );
      },
    }
  );

  return {
    quote_end_points: data?.data?.response?.quote_end_points || ({} as any),
    request_data: data?.data?.request,
    isEnquiryLoading: isLoading,
  };
};

export const useGetChronicDiseasesQuery = () => {
  const { data } = useQuery({
    queryKey: ["chronic-disease"],
    queryFn: () => api.get("api/GetChronicDiseases"),
  });

  return {
    diseases: data?.data?.data as { id: number; disease: string }[],
  };
};
