import { Formik } from "formik";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import {
  useGetProposalDetailsQuery,
  useUpdateProposalFormData,
} from "../../../../../services/Proposal.service";
import PortabilityStar from "./companies/PortabilityStar";
import starvalidation, { starInitialData } from "./validations/starvalidation";
import { useDispatch } from "react-redux";
import { setProposalData } from "../../../../../modules/proposal.slice";
import * as yup from "yup";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PortabilityCare from "./companies/PortabilityCare";
import PortabilityNiva from "./companies/PortabilityNiva";
import PortabilityHdfc from "./companies/PortabilityHdfc";
import PortabilityAditiyaBirla from "./companies/PortabilityAditiyaBirla";
const getInitialData = (alias, members) => {
  switch (alias) {
    case "star":
      return starInitialData;

    default:
      return {};
  }
};
const getValidation = (alias, members) => {
  switch (alias) {
    case "star":
      return starvalidation(members);

    default:
      yup.object().shape();
  }
};
const Portability = () => {
  const { product, isCKYC, isPortability, isProposalLoading } =
    useGetProposalDetailsQuery();
  const slug = product?.INSURANCE_SLUG;
  const { activeProposal } = useTypedSelector((state) => state.proposal);
  const dispatch = useDispatch();
  const currentIndex = isCKYC ? 5 : 4;
  const navigate = useNavigate();
  const { saveForm, isSaved } = useUpdateProposalFormData();
  useEffect(() => {
    if (isSaved && !isProposalLoading) {
      navigate({
        pathname: "/proposal-summary",
        search: window.location.search,
      });
    } else {
    }
  }, [isSaved, isPortability, isProposalLoading]);
  if (!isPortability) return null;
  if (activeProposal !== currentIndex) return null;

  return (
    <Formik
      initialValues={getInitialData(product.INSURANCE_SLUG)}
      validationSchema={getValidation(product.INSURANCE_SLUG)}
      onSubmit={(values) => {
        saveForm({
          portability_details: values,
          from_screen: "portability",
        });
        dispatch(
          setProposalData({
            portability_details: values,
          })
        );
      }}
    >
      <div>
        {product.INSURANCE_SLUG === "star" && <PortabilityStar />}
        {product.INSURANCE_SLUG === "care" && <PortabilityCare />}
        {product.INSURANCE_SLUG === "niva_bupa" && <PortabilityNiva />}
        {product.INSURANCE_SLUG === "hdfc_ergo_optimasecure" && <PortabilityHdfc/>}
        {product.INSURANCE_SLUG === "aditya_birla_health" && <PortabilityAditiyaBirla/>}
      </div>
    </Formik>
  );
};

export default Portability;
