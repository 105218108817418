import { Box, InputLabel, TextField } from "@mui/material";
import { useField, useFormikContext } from "formik";
import React, { useState } from "react";

interface SelectFieldProps {
  labelName: string | React.ReactNode;
  name: string;
  error?: boolean;
  helperText?: string;
  readOnly?: boolean;
  maxLength?: number;
  onInput?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  inlineValidate?: boolean;
  type?: string;
  required?: boolean; 
  placeholder?: string;
  value?: string; 
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  suggestions?: Array<{ id: string | number; label: string }>;
  onSuggestionClick?: (suggestion: { id: string | number; label: string }) => void;
}

const SelectField: React.FC<SelectFieldProps> = ({
  name,
  labelName,
  required,
  suggestions = [],
  onSuggestionClick,
  value,
  ...props
}) => {
  const [field, meta] = useField(name);
  const { submitCount, setFieldValue } = useFormikContext();
  const [showDropdown, setShowDropdown] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setFieldValue(name, inputValue);  // Update Formik's state directly
    setShowDropdown(inputValue.length > 0);  // Show dropdown only when there's input
    props.onChange?.(e);  // Call external onChange if provided
  };

  const handleSuggestionClick = (suggestion: { id: string | number; label: string }) => {
    setFieldValue(name, suggestion.label);  // Update Formik's state directly
    onSuggestionClick?.(suggestion);
    setShowDropdown(false);
  };

  const config = {
    ...field,
    ...props,
    onChange: handleInputChange,  // Override default onChange
    error: meta.error && (meta.touched || submitCount) ? true : undefined,
    helperText: meta.error && (meta.touched || submitCount) ? meta.error : undefined,
  };

  return (
    <Box position="relative">
      <InputLabel sx={{ fontSize: "15px", mb: 1, textOverflow: "unset", whiteSpace: "pre-line" }}>
        {labelName}
        {required && <span style={{ color: "red", fontWeight: "bold" }}>{" *"}</span>}
      </InputLabel>
      <TextField
        {...config}
        value={field.value || ""}  // Bind directly to field.value to allow clearing
        size="small"
        fullWidth
        placeholder={props.placeholder}
        sx={{
          "& input": { color: "#2D2C5F", fontSize: "16px", pt: "12px" },
          "& fieldset": { height: "50px" },
        }}
        inputProps={{ readOnly: props.readOnly, maxLength: props.maxLength }}
      />
      {showDropdown && suggestions.length > 0 && (
        <ul
          style={{
            position: "absolute",
            top: "100%",
            left: 0,
            right: 0,
            maxHeight: "150px",
            overflowY: "auto",
            border: "1px solid #ccc",
            backgroundColor: "white",
            zIndex: 1,
            padding: "0",
            margin: "0",
            listStyle: "none",
          }}
        >
          {suggestions.map((suggestion) => (
            <li
              key={suggestion.id}
              onClick={() => handleSuggestionClick(suggestion)}
              style={{
                padding: "8px",
                cursor: "pointer",
                borderBottom: "1px solid #ddd",
                backgroundColor: "#fff",
              }}
              onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#f0f0f0")}
              onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#fff")}
            >
              {suggestion.label}
            </li>
          ))}
        </ul>
      )}
    </Box>
  );
};

export default React.memo(SelectField);
