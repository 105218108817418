export const reasonOptions = [
  {
    OPTION_KEY: "price_is_better",
    OPTION_VAL: "Price is Better",
  },
  {
    OPTION_KEY: "other",
    OPTION_VAL: "Any Other",
  },
];
export const prev_policy_type_option = [
  {
    OPTION_KEY: "individual",
    OPTION_VAL: "individual",
  },
  {
    OPTION_KEY: "group",
    OPTION_VAL: "group",
  },
];
export const insuranceCompanyNames = [
  { OPTION_KEY: "AI", OPTION_VAL: "AGRICULTURE INSURANCE CO.OF INDIA LTD" },
  { OPTION_KEY: "AMHI", OPTION_VAL: "Apollo Munich Health Insurance Co Ltd" },
  { OPTION_KEY: "BAGI", OPTION_VAL: "BAJAJ ALLIANZ GENERAL INSURANCE CO. LTD" },
  { OPTION_KEY: "BAXAGI", OPTION_VAL: "BHARTI AXA GENERAL INSURANCE CO.LTD" },
  { OPTION_KEY: "CMSGI", OPTION_VAL: "CHOLAMANDALAM MS GENERAL INSURANCE COLTD" },
  { OPTION_KEY: "CHNHB", OPTION_VAL: " Calcutta Hospital and Nursing Home Benefits Association Limited" },
  { OPTION_KEY: "TTK", OPTION_VAL: "Cigna TTK Health Insurance Company Limited" },
  { OPTION_KEY: "ECGC", OPTION_VAL: "EXPORT CREDIT GUARANTEE CORPORATION" },
  { OPTION_KEY: "FGII", OPTION_VAL: "FUTURE GENERALI INDIA INSURANCE CO.LTD" },
  { OPTION_KEY: "HDFCERGOGI", OPTION_VAL: "HDFC ERGO GENERAL INSURANCE COMPANY LTD" },
  { OPTION_KEY: "ICICI", OPTION_VAL: " ICICI LOMBARD GENERAL INSURANCE CO. LTD" },
  { OPTION_KEY: "IFFCOTGI", OPTION_VAL: "IFFCO TOKIO GENERAL INSURANCE CO.LTD" },
  { OPTION_KEY: "KOTAK", OPTION_VAL: "KOTAK MAHINDRA GENERAL INSURANCE COMPANY LIMITED" },
  { OPTION_KEY: "LTGI", OPTION_VAL: "L & T General Insurance Company Limited" },
  { OPTION_KEY: "Liberty", OPTION_VAL: "Liberty Videocon General Insurance company" },
  { OPTION_KEY: "MBHI", OPTION_VAL: "MAX Bupa Health Insurance Company Ltd" },
  { OPTION_KEY: "NI", OPTION_VAL: "NATIONAL INSURANCE COMPANY LIMITED" },
  { OPTION_KEY: "RQBEGI", OPTION_VAL: "RAHEJA QBE GENERAL INSURANCE CO.LTD" },
  { OPTION_KEY: "RGI", OPTION_VAL: "RELIANCE GENERAL INSURANCE COMPANY LTD" },
  { OPTION_KEY: "RSAI", OPTION_VAL: "ROYAL SUNDARAM ALLIANCE INSURANCE CO LTD" },
  { OPTION_KEY: "RHI", OPTION_VAL: "Religare" },
  { OPTION_KEY: "SBIGI", OPTION_VAL: "SBI GENERAL INSURANCE COMPANY LTD" },
  { OPTION_KEY: "SGI", OPTION_VAL: "SHRIRAM GENERAL INSURANCE CO.LTD" },
  { OPTION_KEY: "ABHI", OPTION_VAL: "ADITYA BIRLA HEALTH INSURANCE COMPANY LTD" },
  { OPTION_KEY: "MAGMA", OPTION_VAL: "MAGMA HDI GENERAL INSURANCE COMPANY LTD" },
  { OPTION_KEY: "TNIA", OPTION_VAL: "THE NEW INDIA ASSURANCE CO.LTD" },
  { OPTION_KEY: "Tata AGIGI", OPTION_VAL: "TATA AIG GENERAL INSURANCE COMPANY LTD" },
  { OPTION_KEY: "UII", OPTION_VAL: "UNITED INDIA INSURANCE CO.LTD" },
  { OPTION_KEY: "USGI", OPTION_VAL: "UNIVERSAL SOMPO GENERAL INSURANCE CO.LTD" },
  { OPTION_KEY: "TOI", OPTION_VAL: "THE ORIENTAL INSURANCE CO. LTD" },
];

export const policy_type = [
  {
    OPTION_KEY: "floater",
    OPTION_VAL: "Floater",
  },
  {
    OPTION_KEY: "individual",
    OPTION_VAL: "Individual",
  },
];

export const continuous_coverage_year_options = [
  {
    OPTION_KEY: 1,
    OPTION_VAL: "1 year",
  },
  {
    OPTION_KEY: 2,
    OPTION_VAL: "2 year",
  },
  {
    OPTION_KEY: 3,
    OPTION_VAL: "3 year",
  },
  {
    OPTION_KEY: 4,
    OPTION_VAL: "4 year",
  },
];
