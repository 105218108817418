import React from "react";
import { Typography } from "@mui/material";
import { useFormikContext } from "formik";

// Helper function to access nested values
export const reduceObj = (obj: any, key: string) => {
  const keys = key.split(".");

  let temp = obj[keys[0]];

  for (let i = 1; i < keys.length; ++i) {
    if (temp) {
      temp = temp[keys[i]];
    } else return undefined;
  }
  return temp;
};

// Radio component
interface RadioButtonProps {
  name: string;
  label: string;
  handleChange?: (value: string) => void;
  required?: boolean;
  description?: string;
}

const RadioButtonGroup: React.FC<RadioButtonProps> = ({ name, label, handleChange, required = false, description }) => {
  const { setFieldValue, values } = useFormikContext();

  const handleRadioChange = (value: string) => {
    setFieldValue(name, value);
    if (handleChange) {
      handleChange(value);
    }
  };

  return (
    <div className="radio-wrapper">
<Typography variant="body1" fontSize="16px">
  {label}
  {required && (
    <span style={{ color: "red", fontWeight: "bold" }}> *</span>
  )}
</Typography>

      <div className="radio-container">
        <input
          type="radio"
          id={name.replaceAll(".", "") + "Retail"}
          name={name}
          value={"Retail"}
          checked={reduceObj(values, name) === "Retail"}
          onChange={() => handleRadioChange("Retail")}
        />
        <label htmlFor={name.replaceAll(".", "") + "Retail"}>Retail</label>

        <input
          type="radio"
          id={name.replaceAll(".", "") + "Group"}
          name={name}
          value={"Group"}
          checked={reduceObj(values, name) === "Group"}
          onChange={() => handleRadioChange("Group")}
        />
        <label htmlFor={name.replaceAll(".", "") + "Group"}>Group</label>
      </div>
    </div>
  );
};

export default RadioButtonGroup;
