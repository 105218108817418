export const sumInsuredOptions = [
  {
    label: "1L to 3L",
    value: "100000-300000",
  },
  {
    label: "4L to 5L",
    value: "400000-500000",
  },
  {
    label: "6L to 9L",
    value: "600000-900000",
  },
  {
    label: "10L to 14L",
    value: "1000000-1400000",
  },
  {
    label: "15L to 24L",
    value: "1500000-2400000",
  },
  {
    label: "25L to 99L",
    value: "2500000-9900000",
  },
  {
    label: "1Cr+",
    value: "10000000-100000000",
  },
];

export const deductibleOptions = [
  {
    label: "₹ 0",
    value: "0",
  },
  {
    label: "₹ 50,000",
    value: "50000",
  },
  {
    label: "₹ 75,000",
    value: "75000",
  },
  {
    label: "₹ 1 lakh",
    value: "100000",
  },
  {
    label: "₹ 2 lakh",
    value: "200000",
  },
  {
    label: "₹ 2.5 lakh",
    value: "250000",
  },
  {
    label: "₹ 3 lakh",
    value: "300000",
  },
  {
    label: "₹ 3.5 lakh",
    value: "350000",
  },
  {
    label: "₹ 4 lakh",
    value: "400000",
  },
  {
    label: "₹ 4.5 lakh",
    value: "450000",
  },
  {
    label: "₹ 5 lakh",
    value: "500000",
  },
  {
    label: "₹ 5.5 lakh",
    value: "550000",
  },
  {
    label: "₹ 6 lakh",
    value: "600000",
  },
  {
    label: "₹ 7 lakh",
    value: "700000",
  },
  {
    label: "₹ 7.5 lakh",
    value: "750000",
  },
  {
    label: "₹ 8 lakh",
    value: "800000",
  },
  {
    label: "₹ 9 lakh",
    value: "900000",
  },
  {
    label: "₹ 10 lakh",
    value: "1000000",
  },
  {
    label: "₹ 12.5 lakh",
    value: "1250000",
  },
  {
    label: "₹ 15 lakh",
    value: "1500000",
  },
  {
    label: "₹ 20 lakh",
    value: "2000000",
  },
  {
    label: "₹ 25 lakh",
    value: "2500000",
  },
  {
    label: "₹ 30 lakh",
    value: "3000000",
  },
  {
    label: "₹ 40 lakh",
    value: "4000000",
  },
  {
    label: "₹ 50 lakh",
    value: "5000000",
  },
  {
    label: "₹ 75 lakh",
    value: "7500000",
  },
  {
    label: "₹ 1 Cr",
    value: "10000000",
  },
  {
    label: "₹ 1.5 Cr",
    value: "15000000",
  },
  {
    label: "₹ 2 Cr",
    value: "20000000",
  },
  {
    label: "₹ 3 Cr",
    value: "30000000",
  },
  {
    label: "₹ 10 Cr",
    value: "100000000",
  },
];
export const planTypeOptions = [
  {
    label : "Base Plan",
    value :"50000"
  },
  {
    label : "Topup Plans",
    value : "100000000"
  }
]