import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { setProposalData } from "../../../../modules/proposal.slice";
import {
  useGenerateCKYC,
  useUploadCignaDocMutation,
  useVerifyCareCKYC,
} from "../../../../services/CKYC.service";
import { useUpdateProposalFormData } from "../../../../services/Proposal.service";
import {
  allowOnlyAlphabets,
  allowOnlyAlphabetsAndNumbers,
  allowOnlyPanCard,
  regex,
} from "../../../../utils/inputUtils";
import * as yup from "yup";
import Select from "../InputComponents/Select";
import TextInput from "../InputComponents/TextInput";
import YesNo from "../InputComponents/YesNo";
import { Check } from "@mui/icons-material";

const addressProofOptions = [
  { OPTION_KEY: "1", OPTION_VAL: "Passport" },
  { OPTION_KEY: "2", OPTION_VAL: "Voter Id" },
  { OPTION_KEY: "3", OPTION_VAL: "Driving License" },
  { OPTION_KEY: "7", OPTION_VAL: "Pan" },
];

const CKYCCareForm = () => {
  const { saveForm, isSaving } = useUpdateProposalFormData();
  const dispatch = useDispatch();
  const { activeProposal, proposalData } = useTypedSelector(
    (state) => state.proposal
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { upload } = useUploadCignaDocMutation();
  const { verifyCareCKYC, isVerifyCareCKYC } = useVerifyCareCKYC();

  const handleVerifyPan = async () => {
    const { data } = await verifyCareCKYC({
      pan_no: proposer_details?.pan_no,
      dob: proposer_details?.dob,
    });
    dispatch(
      setProposalData({
        ...proposalData,
        ckyc_details: {
          ...proposalData?.ckyc_details,
          ...data?.return_data,
          status: data?.status,
        },
      })
    );
  };

  const proposer_details = proposalData?.proposer_details || {};
  const ckyc_details = proposalData?.ckyc_details || {};

  const initialValues = {
    pan_no: proposer_details?.pan_no || "",
    address_document_type: ckyc_details?.address_document_type || "",
    identity_document_type: ckyc_details?.identity_document_type || "",
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={yup.object().shape({
          pan_no: yup
            .string()
            .required("PAN Number is required")
            .matches(regex.pan_no, "Please Enter a valid PAN Number"),
          address_document_type: yup.string().when("status", {
            is: 500,
            then: yup.string().required("Address Proof is required"),
          }),
          proposer_address_proof: yup.mixed().when("address_document_type", {
            is: (val) => val && val !== "",
            then: yup.mixed().required("Please upload Address Proof"),
          }),
          identity_document_type: yup.string().when("status", {
            is: 500,
            then: yup.string().required("Identity Proof is required"),
          }),
          proposer_identity_proof: yup.mixed().when("identity_document_type", {
            is: (val) => val && val !== "",
            then: yup.mixed().required("Please upload Identity Proof"),
          }),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const proposer_address_proof = values?.proposer_address_proof;
            const proposer_identity_proof = values?.proposer_identity_proof;
        
            if (!ckyc_details?.verification_status) {
              await upload(values);
              
              dispatch(
                setProposalData({
                  ...proposalData,
                  ckyc_details: {
                    ...ckyc_details,
                    ...values,
                    proposer_address_proof,
                    proposer_identity_proof,
                  },
                })
              );
            }
        
            await saveForm({
              ckyc_details: {
                ckyc_reference_id: ckyc_details?.ckycReferenceId,
                ...ckyc_details,
                ...values,
              },
              from_screen: "ckyc",
            });
          } catch (error) {
            console.error("Submission error:", error);
          } finally {
            setSubmitting(false);
          }
        }}
        
      >
        {({ setFieldValue, values, submitForm, isSubmitting }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <TextInput
                  label="Pan No."
                  name="pan_no"
                  onInput={allowOnlyPanCard}
                  readOnly
                />
              </Grid>

              <Grid item md={6} xs={12}>
                {ckyc_details?.verification_status ? (
                  <Chip
                    sx={{ mt: "36px" }}
                    variant="outlined"
                    label="Verified"
                    icon={<Check />}
                    color="success"
                  />
                ) : (
                  <Button
                    onClick={handleVerifyPan}
                    disabled={isVerifyCareCKYC}
                    variant="contained"
                    sx={{ mt: "32px" }}
                    type="button"
                  >
                    {isVerifyCareCKYC ? (
                      <CircularProgress size="30px" />
                    ) : (
                      "Verify PAN No."
                    )}
                  </Button>
                )}
              </Grid>

              {ckyc_details?.status === 500 && (
                <>
                  <Grid item md={6} xs={12}>
                    <Select
                      name="address_document_type"
                      options={addressProofOptions}
                      label="Address Proof"
                      value={values.address_document_type}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    {values.address_document_type && (
                      <TextInput
                        type="file"
                        name="proposer_address_proof"
                        label="Upload Address Proof Document"
                        onChange={(e) =>
                          setFieldValue("proposer_address_proof", e.target.files[0])
                        }
                      />
                    )}
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Select
                      name="identity_document_type"
                      options={addressProofOptions}
                      label="Identity Proof"
                      value={values.identity_document_type}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    {values.identity_document_type && (
                      <TextInput
                        type="file"
                        name="proposer_identity_proof"
                        label="Upload Identity Proof Document"
                        onChange={(e) =>
                          setFieldValue("proposer_identity_proof", e.target.files[0])
                        }
                      />
                    )}
                  </Grid>
                </>
              )}

              {(ckyc_details?.verification_status ||
                (values.proposer_identity_proof && values.proposer_address_proof)) && (
                <Grid item xs={12} justifyContent="center" display={"flex"} my={1}>
                  <Box sx={{ position: "relative" }}>
                    <Button
                      size={isMobile ? "medium" : "large"}
                      variant="contained"
                      disabled={isSaving}
                      onClick={submitForm}
                    >
                      {isSaving ? (
                        <CircularProgress size="30px" />
                      ) : (
                        "Proceed to Insurer Details"
                      )}
                    </Button>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CKYCCareForm;
