import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  MenuItem,
  TextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import {
  setActiveProposal,
  setProposalData,
} from "../../../../modules/proposal.slice";
import {
  useGetBankDetailsByIFSC,
  useGetHdfcBankNames,
  useGetOccupationQuery,
  useGetPincodeMutation,
  useGetProposalDetailsQuery,
  useUpdateProposalFormData,
} from "../../../../services/Proposal.service";
import { genderOptions } from "../../options.utils";
import FormLabel from "../FormLabel";
import Select from "../InputComponents/Select";
import TextInput from "../InputComponents/TextInput";
import * as yup from "yup";
import DateComp from "../InputComponents/DateComp";
import moment from "moment";
import InputSideEffect from "../InputComponents/InputSideEffect";
import {
  allowOnlyNumbers,
  allowOnlyPanCard,
  allowOnlyAlphabets,
  regex,
  toCapitalize,
  allowAddress,
} from "../../../../utils/inputUtils";
import Height from "../InputComponents/Height";
import { useEffect, useState } from "react";
import {
  useGenerateCKYC,
  useVerifyCareCKYC,
  useVerifyPanCKYC,
} from "../../../../services/CKYC.service";
import useUrlQuery from "../../../../hooks/useUrlQuery";
import { useQueryClient } from "@tanstack/react-query";
import YesNo from "../InputComponents/YesNo";
import api from "../../../../api/api";
import { MembersInterface } from "../../../../modules/landing.slice";
import Swal from "sweetalert2";
import React from "react";
import CachedIcon from "@mui/icons-material/Cached";
import BankTextInput from "../InputComponents/BankTextInput";
import CloseIcon from "@mui/icons-material/Close";
import SelectField from "../InputComponents/SelectField";
const findIfMarried = (
  options: MembersInterface[] | undefined,
  selected:
    | "self"
    | "spouse"
    | "father"
    | "mother"
    | undefined
    | "son"
    | "daughter"
) => {
  if (
    selected === "self" &&
    options?.some((item) => item.relation === "spouse")
  )
    return true;
  if (selected === "father" || selected === "mother" || selected === "spouse") {
    return true;
  }
};
function getFirstLastName(fullName: string | undefined) {
  if (!fullName) return { first_name: null, last_name: null };
  const title = ["Mr", "Mrs", "Dr", "Miss", "Ms"];
  const nameParts = fullName.trim().replace("  ", " ").split(/\s+/);
  let first_index = 0;
  let first_name = nameParts[0];
  if (title.includes(first_name)) {
    first_index = 1;
    first_name = nameParts[1];
  }
  const last_name = nameParts.slice(first_index + 1).join(" ");
  return { first_name, last_name };
}
const ProposerForm = () => {
  const dispatch = useDispatch();
  const { activeProposal, proposalData } = useTypedSelector(
    (state) => state.proposal
  );
  const { verifyCKYC, isVerifyCKYC, resetVerify } = useVerifyPanCKYC();
  const { getUrlQuery } = useUrlQuery();
  const PROPOSAL_ID = getUrlQuery("proposal_id");
  const { enquiry, isProposalLoading, product, isCKYC, quote_data } =
    useGetProposalDetailsQuery();
  const { asyncSaveForm, saveForm, isSaving } = useUpdateProposalFormData();
  const { verifyCareCKYC } = useVerifyCareCKYC();
  const self = enquiry?.members?.find((member) => member.relation === "self");
  const spouse = enquiry?.members.find((item) => item.relation === "spouse");
  const { generateCKYC, isGeneratingCKYC, generateCkycData } =
    useGenerateCKYC();
  const { occupation_list } = useGetOccupationQuery();
  const { cityOptions, stateOptions, getCityState } = useGetPincodeMutation();
  const [ifscCode, setIfscCode] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setRead(true);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const [isRead, setRead] = useState(false);
  const {
    mutate: fetchBankDetails,
    data: bankDetails,
    isLoading,
    isError,
    error,
  } = useGetBankDetailsByIFSC();
  const [query, setQuery] = useState("");
  const [showDropdown, setShowDropdown] = useState(false); // State to control dropdown visibility
  const { bankNames, refetch, isLoadings, errors } = useGetHdfcBankNames(query);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, [activeProposal]);
  const {
    cityOptions: commCityOptions,
    stateOptions: commStateOptions,
    isCityStateFetched: isCommCityStateFetched,
    getCityState: getCommCityState,
  } = useGetPincodeMutation();
  const queryClient = useQueryClient();
  const { members } = useGetProposalDetailsQuery();
  const selected_self_relation = members?.find(
    (item) => item.relation === "self"
  );
  const members_eligible_to_be_proposer = [
    {
      relation: "self",
      age: selected_self_relation ? selected_self_relation.age : 0,
      gender: selected_self_relation
        ? selected_self_relation.gender
        : undefined,
    } as MembersInterface,
    ...(members?.filter(
      (item) =>
        !item.relation.toLowerCase().includes("son") &&
        !item.relation.toLowerCase().includes("daughter") &&
        !item.relation.toLowerCase().includes("self")
    ) || []),
  ];
  useEffect(() => {
    if (ifscCode.length === 11) {
      fetchBankDetails(ifscCode);
    }
  }, [ifscCode, fetchBankDetails]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  if (activeProposal > 0 || isProposalLoading) return null;
  const initialData = {
    gender: members_eligible_to_be_proposer?.[0].gender || "",
    first_name: "",
    middle_name: "",
    last_name: "",
    dob: proposalData?.proposer_details?.dob,
    pan_no: "",
    annual_income: "",
    email: "",
    mobile_no: enquiry?.mobile_no,
    alternate_no: "",
    address_line_1: "",
    address_line_2: "",
    address_line_3: "",
    pincode: enquiry?.pincode,
    pincode_value: cityOptions?.map((val) => val.OPTION_VAL),
    city: "",
    state: "",
    comm_address_line_1: "",
    comm_address_line_2: "",
    comm_address_line_3: "",
    comm_pincode: "",
    comm_city: "",
    comm_state: "",
    marital_status: findIfMarried(
      members_eligible_to_be_proposer,
      members_eligible_to_be_proposer?.some((item) => item.relation === "self")
        ? "self"
        : members_eligible_to_be_proposer?.[0].relation
    )
      ? "Married"
      : "",
    is_comm_same_as_above: true,
    feet: "",
    inches: "",
    relation: members_eligible_to_be_proposer?.some(
      (item) => item.relation === "self"
    )
      ? "self"
      : members_eligible_to_be_proposer?.[0].relation,
    occupation: "",
    PEP_QUESTION_1: "N",
    NRI_QUESTION_1: "N",
    isPhysicalCopy: "",
    // isAgree: "",
    isConstentAgree: "",
    bankAccountType: "",
    bankAccountNo: "",
    BANK: "",
    BRANCH: "",
    IFSC: "",
    CENTRE: "",
    STATE: "",
    micrCode: "",
    bankName: "",
  };
  const isReadOnly =
    proposalData?.proposer_details?.ckyc &&
    (product?.INSURANCE_SLUG.includes("hdfc_ergo_optimasecure") ||
      product?.INSURANCE_SLUG === "aditya_birla_health");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setQuery(value);
    if (value.length >= 1) {
      refetch(); // Fetch data when input has at least 1 character
      setShowDropdown(true); // Show dropdown when typing
    } else {
      setShowDropdown(false); // Hide dropdown if input is cleared
    }
  };

  const handleBankClick = (suggestion: { id: any; label: any }) => {
    setQuery(suggestion.label); // Update query with selected suggestion label
    setShowDropdown(false); // Close dropdown
  };

  return (
    <>
      <FormLabel label="Great! Let’s start with proposer details" />
      <Formik
        initialValues={
          proposalData?.proposer_details
            ? {
                ...proposalData?.proposer_details,
                dob: proposalData?.proposer_details?.dob,
                pincode_value: cityOptions
                  ?.map((val) => val.OPTION_VAL)
                  .join(", "),
              }
            : {
                ...initialData,
                dob: moment().subtract(
                  members_eligible_to_be_proposer?.find(
                    (item) => item.relation === initialData.relation
                  )?.age,
                  "years"
                ),
              }
        }
        onSubmit={async (values) => {
          if (product?.INSURANCE_SLUG === "niva_bupa") {
            if (
              values.PEP_QUESTION_1 === "Y" ||
              values.NRI_QUESTION_1 === "Y"
            ) {
              Swal.fire({
                text: "Please contact our customer service to continue your journey.",
              });
              return;
            }
          }
          if (
            product?.INSURANCE_SLUG === "aditya_birla_health" ||
            product?.INSURANCE_SLUG === "hdfc_ergo_optimasecure"
          ) {
            if (values?.NRI_QUESTION_1 === "Y") {
              Swal.fire({
                text: "Please contact our customer service to continue your journey.",
              });
              return;
            }
          }
          const same_comm = {
            comm_address_line_1: values.address_line_1,
            comm_address_line_2: values.address_line_2,
            comm_address_line_3: values.address_line_3,
            comm_pincode: values.pincode,
            comm_city: values.city,
            comm_state: values.state,
            pincode_value: cityOptions?.map((val) => val.OPTION_VAL).join(", "),
          };
          let panStatus: any = {};
          const { first_name, last_name } = getFirstLastName(
            panStatus?.data?.return_data?.name
          );
          dispatch(
            setProposalData({
              proposer_details: {
                ...values,
                ...(values.is_comm_same_as_above && same_comm),
                ...(first_name && last_name && { first_name, last_name }),
                pincode_value: cityOptions?.forEach((val) => val.OPTION_VAL),
              },
              ckyc_details: {
                ...(proposalData?.ckyc_details || {}),
                ckycId: panStatus?.data?.return_data?.ckycId,
                ckycReferenceId:
                  generateCkycData?.data?.return_data?.ckycReferenceId,
              },
              bank_details: {
                bankAccountType: values.bankAccountType || "",
                bankAccountNo: values.bankAccountNo || "",
                BANK: bankDetails?.BANK ? bankDetails?.BANK : values?.BANK,
                BRANCH: bankDetails?.BRANCH
                  ? bankDetails?.BRANCH
                  : values?.BRANCH,
                ifscCode: bankDetails?.IFSC ? bankDetails?.IFSC : values?.IFSC,
                CENTRE: bankDetails?.CENTRE
                  ? bankDetails?.CENTRE
                  : values?.CENTRE,
                STATE: bankDetails?.STATE ? bankDetails?.STATE : values?.STATE,
                micrCode: bankDetails?.micrCode
                  ? bankDetails?.micrCode
                  : values?.micrCode,
                bankName: bankDetails?.bankName
                  ? bankDetails?.bankName
                  : values?.bankName,
              },
            })
          );
          await asyncSaveForm({
            proposer_details: {
              ...values,
              ...(values.is_comm_same_as_above && same_comm),
              ...(first_name && last_name && { first_name, last_name }),
            },
            ckyc_details: {
              ...(proposalData?.ckyc_details || {}),
              ckycId: panStatus?.data?.return_data?.ckycId,
            },
            from_screen: "proposal",
            bank_details: {
              bankAccountType: values.bankAccountType || "",
              bankAccountNo: values.bankAccountNo || "",
              BANK: bankDetails?.BANK ? bankDetails?.BANK : values?.BANK,
              BRANCH: bankDetails?.BRANCH
                ? bankDetails?.BRANCH
                : values?.BRANCH,
              ifscCode: bankDetails?.IFSC ? bankDetails?.IFSC : values?.IFSC,
              CENTRE: bankDetails?.CENTRE
                ? bankDetails?.CENTRE
                : values?.CENTRE,
              STATE: bankDetails?.STATE ? bankDetails?.STATE : values?.STATE,
              micrCode: bankDetails?.micrCode
                ? bankDetails?.micrCode
                : values?.micrCode,
              bankName: bankDetails?.bankName
                ? bankDetails?.bankName
                : values?.bankName,
            },
          });
          if (
            product?.INSURANCE_SLUG === "star" ||
            product?.INSURANCE_SLUG.includes("hdfc_ergo_optimasecure")
          ) {
            panStatus = await verifyCKYC({
              pan_no: values?.pan_no,
              PROPOSAL_ID,
              INSURANCE_SLUG: product.INSURANCE_SLUG,
            });
            await api.post("api/RecalculateHealthPremium", {
              proposal_id: PROPOSAL_ID,
            });
          }
          if (product?.INSURANCE_SLUG === "aditya_birla_health") {
            await api.post("api/ABHICkycResponse", {
              proposal_id: PROPOSAL_ID,
            });
          }
          queryClient.invalidateQueries(["proposal"]);
          if (panStatus?.data?.CKYC_LINK && panStatus?.data?.IS_CKYC === "N") {
            window.open(panStatus?.data?.CKYC_LINK, "_self");
          }
          resetVerify();
        }}
        validationSchema={yup.object().shape({
          first_name: yup.string().required("First Name is required"),
          middle_name: yup.string().nullable(),
          last_name: yup.string().required("Last Name is required"),
          gender: yup.string().required("Gender is required"),
          dob: yup
            .string()
            .required("Date of birth is required")
            .test("dob", "Please Enter a valid Format", (val) => {
              return moment(val, "YYYY-MM-DD").isValid();
            })
            .test(
              "dob",
              `Age should be between ${product?.ADULT_MIN} and ${product?.ADULT_MAX}`,
              (val) => {
                return moment(val, "YYYY-MM-DD").isBetween(
                  moment()
                    .subtract(product?.ADULT_MAX, "years")
                    .subtract(1, "year"),
                  moment().subtract(product?.ADULT_MIN, "years")
                );
              }
            ),
          pan_no: yup
            .string()
            .required("PAN Number is required")
            .matches(regex.pan_no, "Please Enter a valid PAN Number"),
          annual_income: yup.string().required("Annual Income is required"),
          email: yup
            .string()
            .required("Email is required")
            .email("Please Enter a valid Email"),
          alternate_no: yup
            .string()
            .nullable()
            .matches(regex.mobile_no, "Please Enter a valid Mobile Number"),
          mobile_no: yup
            .string()
            .required("Mobile Number is required")
            .matches(regex.mobile_no, "Please Enter a valid Mobile Number"),
          address_line_1: yup
            .string()
            .required("Address is required")
            .max(60, "Address should not be more than 60 characters"),
          address_line_2: yup
            .string()
            .required("Address is required")
            .max(60, "Address should not be more than 60 characters"),
          pincode: yup
            .string()
            .required("Pincode is required")
            .matches(/^[1-9][0-9]{5}$/, "Please enter a valid pincode"),
          city: yup.string().required("City is required"),
          state: yup.string().required("State is required"),
          comm_address_line_1: yup.string().when("is_comm_same_as_above", {
            is: (val: boolean) => !val,
            then: yup
              .string()
              .required("Address is required")
              .max(60, "Address should not be more than 60 characters"),
          }),
          comm_address_line_2: yup.string().when("is_comm_same_as_above", {
            is: (val: boolean) => !val,
            then: yup
              .string()
              .required("Address is required")
              .max(60, "Address should not be more than 60 characters"),
          }),
          comm_pincode: yup.string().when("is_comm_same_as_above", {
            is: (val: boolean) => !val,
            then: yup
              .string()
              .required("Pincode is required")
              .matches(/^[1-9][0-9]{5}$/, "Please enter a valid pincode"),
          }),
          occupation: yup.string().required("Occupation is required"),
          marital_status: yup.string().required("Marital Status is required"),
          feet: yup
            .string()
            .required("Height is required")
            .test("feet", "Please enter a valid height", function (val) {
              return Number(val) > 0;
            }),
          inches: yup.string().required("Height is required"),
          weight: yup
            .string()
            .required("Weight is required")
            .test("weight", "Max Weight allowed is 180", (val) => {
              if (product?.INSURANCE_SLUG !== "star") return true;
              else return Number(val) <= 180;
            })
            .test(
              "weight",
              "Please enter a valid Weight",
              (val) => Number(val) > 0
            ),
          is_comm_same_as_above: yup.boolean(),
          relation: yup.string().required("Relation is required"),
          isAgreeManipalCigna: yup.boolean().when("product?.INSURANCE_SLUG", {
            is: "manipal_cigna",
            then: yup.boolean().oneOf([true], "You must agree to proceed"),
          }),
        })}
      >
        {({ submitForm, values, setFieldValue }) => (
          <Form
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            <Grid container spacing={2}>
              <InputSideEffect
                effect={() => {
                  if (!proposalData?.proposer_details) {
                    const selectedRelation =
                      members_eligible_to_be_proposer?.find(
                        (item) => item.relation === values.relation
                      );
                    setFieldValue("gender", selectedRelation?.gender);
                    if (selectedRelation?.age) {
                      setFieldValue(
                        "dob",
                        moment()
                          .subtract(selectedRelation?.age, "years")
                          .format("YYYY-MM-DD")
                      );
                    } else setFieldValue("dob", null);
                    const isMarried = findIfMarried(
                      members_eligible_to_be_proposer,
                      values.relation
                    );
                    if (isMarried) {
                      setFieldValue("marital_status", "Married");
                    } else setFieldValue("marital_status", "");
                  }
                }}
                dependencies={[values.relation]}
              >
                <Grid item xs={12} md={6}>
                  <Select
                    options={
                      members_eligible_to_be_proposer?.map((item) => ({
                        OPTION_KEY: item.relation,
                        OPTION_VAL:
                          item.relation.charAt(0).toUpperCase() +
                          item.relation.slice(1),
                      })) || []
                    }
                    label="Who is Proposer?"
                    name="relation"
                  />
                </Grid>
              </InputSideEffect>
              <Grid item xs={12} md={6} />
              <Grid item xs={12} md={6}>
                <TextInput
                  label="First Name"
                  name="first_name"
                  onInput={(e) => {
                    allowOnlyAlphabets(e);
                    toCapitalize(e);
                  }}
                  readOnly={isReadOnly}
                  required={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  label="Middle Name (Optional)"
                  name="middle_name"
                  onInput={(e) => {
                    allowOnlyAlphabets(e);
                    toCapitalize(e);
                  }}
                  readOnly={isReadOnly}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  label="Last Name"
                  name="last_name"
                  onInput={(e) => {
                    allowOnlyAlphabets(e);
                    toCapitalize(e);
                  }}
                  readOnly={isReadOnly}
                  required={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Select
                  readOnly={
                    !!members_eligible_to_be_proposer?.find(
                      (item) => item.relation === values.relation
                    )?.gender
                  }
                  label="Gender"
                  name="gender"
                  options={genderOptions}
                  required={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Select
                  label="Marital Status"
                  name="marital_status"
                  required={true}
                  readOnly={findIfMarried(
                    members_eligible_to_be_proposer,
                    values.relation
                  )}
                  options={[
                    {
                      OPTION_KEY: "Single",
                      OPTION_VAL: "Single",
                    },
                    {
                      OPTION_KEY: "Married",
                      OPTION_VAL: "Married",
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DateComp
                  label="Date of Birth"
                  name="dob"
                  maxDate={moment().subtract(18, "years")}
                  minDate={moment().subtract(100, "years")}
                  readOnly={isReadOnly}
                  defaultValue={proposalData?.proposer_details?.dob}
                  required={true}
                />
              </Grid>{" "}
              <Grid item md={6} xs={12}>
                <Select
                  options={occupation_list || []}
                  label="Occupation"
                  required={true}
                  name={`occupation`}
                />
              </Grid>
              {product?.INSURANCE_SLUG === "aditya_birla_health" &&
                values.occupation === "Others" && (
                  <Grid item xs={12} md={6}>
                    <TextInput
                      label="Other occupation"
                      name="other_occupation"
                      readOnly={isReadOnly}
                      required={true}
                    />
                  </Grid>
                )}
              <Grid item xs={12} md={6}>
                <TextInput
                  label="Pan Number"
                  name="pan_no"
                  onInput={allowOnlyPanCard}
                  readOnly={isReadOnly}
                  required={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Height />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  label="Weight"
                  name={`weight`}
                  onInput={allowOnlyNumbers}
                  maxLength={3}
                  required={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  label="Annual Income"
                  name="annual_income"
                  onInput={allowOnlyNumbers}
                  required={true}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography color="text.secondary" fontWeight="500">
                  Contact
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput label="Email" name="email" required={true} />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  label="Mobile No"
                  name="mobile_no"
                  onInput={allowOnlyNumbers}
                  maxLength={10}
                  required={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  label="Alternate Mobile No"
                  name="alternate_no"
                  onInput={allowOnlyNumbers}
                  maxLength={10}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography color="text.secondary" fontWeight="500">
                  Address
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  label="Address Line 1"
                  name="address_line_1"
                  maxLength={60}
                  onInput={allowAddress}
                  required={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  label="Address Line 2"
                  name="address_line_2"
                  maxLength={60}
                  onInput={allowAddress}
                  required={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  label="Address Line 3"
                  name="address_line_3"
                  maxLength={60}
                  onInput={allowAddress}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputSideEffect
                  dependencies={[values.pincode, quote_data?.COMPANY_SLUG]}
                  effect={() => {
                    if (values.pincode && values.pincode.length === 6)
                      getCityState({
                        pincode: values.pincode,
                        company_alias: quote_data?.COMPANY_SLUG,
                      });
                  }}
                >
                  <TextInput
                    label="Pincode"
                    name="pincode"
                    onInput={allowOnlyNumbers}
                    maxLength={6}
                    required={true}
                  />
                </InputSideEffect>
              </Grid>
              <Grid item xs={12} md={6}>
                <Select
                  options={cityOptions || []}
                  label="City"
                  name="city"
                  required={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputSideEffect
                  dependencies={[stateOptions]}
                  effect={() => {
                    if (stateOptions && stateOptions.length) {
                      setFieldValue("state", stateOptions[0].OPTION_VAL);
                    }
                  }}
                >
                  <TextInput
                    label="State"
                    name="state"
                    required={true}
                    readOnly
                  />
                </InputSideEffect>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  label="Is Communication Address same as above address?"
                  control={<Checkbox />}
                  onChange={(e, checked) => {
                    setFieldValue("is_comm_same_as_above", checked);
                  }}
                  checked={values.is_comm_same_as_above}
                />
              </Grid>
              {!values.is_comm_same_as_above && (
                <>
                  <Grid item xs={12} md={6}>
                    <TextInput
                      label="Address Line 1"
                      name="comm_address_line_1"
                      maxLength={60}
                      onInput={allowAddress}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput
                      label="Address Line 2"
                      name="comm_address_line_2"
                      maxLength={60}
                      onInput={allowAddress}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput
                      label="Address Line 3"
                      name="comm_address_line_3"
                      maxLength={60}
                      onInput={allowAddress}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputSideEffect
                      dependencies={[values.comm_pincode]}
                      effect={() => {
                        if (
                          values.comm_pincode &&
                          values.comm_pincode.length === 6
                        )
                          getCommCityState({
                            pincode: values.comm_pincode,
                            company_alias: quote_data?.COMPANY_SLUG,
                          });
                      }}
                    >
                      <TextInput
                        label="Pincode"
                        name="comm_pincode"
                        onInput={allowOnlyNumbers}
                        maxLength={6}
                      />
                    </InputSideEffect>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Select
                      options={commCityOptions || []}
                      label="City"
                      name="comm_city"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputSideEffect
                      dependencies={[commStateOptions]}
                      effect={() => {
                        if (commStateOptions && commStateOptions.length) {
                          setFieldValue(
                            "comm_state",
                            commStateOptions[0].OPTION_VAL
                          );
                        }
                      }}
                    >
                      <TextInput label="State" name="comm_state" readOnly />
                    </InputSideEffect>
                  </Grid>
                </>
              )}
              <>
                {(product?.INSURANCE_SLUG === "manipal_cigna" ||
                  product?.INSURANCE_SLUG === "aditya_birla_health" ||
                  product?.INSURANCE_SLUG === "hdfc_ergo_optimasecure" ||
                  product?.INSURANCE_SLUG === "niva_bupa") && (
                  <>
                    <Grid item xs={12}>
                      <Typography color="text.secondary" fontWeight="500">
                        Bank Details
                        {isLoading && (
                          <div className="center">
                            <CachedIcon className="loading-icon" />
                          </div>
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Select
                        options={bankAccountType || []}
                        label="Bank Account Type"
                        name="bankAccountType"
                        required={true}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextInput
                        label="Account Number"
                        name="bankAccountNo"
                        maxLength={60}
                        required={true}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextInput
                        label="Name in Bank"
                        name="bankName"
                        maxLength={60}
                        required={true}
                        placeholder={"Enter Your Name"}
                      />
                    </Grid>
                    {product?.INSURANCE_SLUG === "hdfc_ergo_optimasecure" && (
                      <>
                        <Grid item xs={12} md={6}>
                          <SelectField
                            type="text"
                            value={query} // Controlled by query state
                            required={true}
                            onChange={handleInputChange}
                            placeholder="Search for a bank"
                            labelName="Bank Name"
                            name="BANK"
                            suggestions={
                              Array.isArray(bankNames?.response)
                                ? bankNames.response.map(
                                    (bank: { id: any; bank_name: any }) => ({
                                      id: bank.id,
                                      label: bank.bank_name,
                                    })
                                  )
                                : []
                            }
                            onSuggestionClick={handleBankClick}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextInput
                            label="IFSC Code"
                            name="IFSC"
                            maxLength={11}
                            required={true}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextInput
                            label="Branch Name"
                            name="BRANCH"
                            required={true}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextInput
                            label="MICR Code"
                            name="micrCode"
                            maxLength={60}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextInput
                            label="Bank City"
                            name="CENTRE"
                            required={true}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextInput
                            label="Bank State"
                            name="STATE"
                            required={true}
                          />
                        </Grid>
                      </>
                    )}
                    {product?.INSURANCE_SLUG !== "hdfc_ergo_optimasecure" && (
                      <>
                        <Grid item xs={12} md={6}>
                          <BankTextInput
                            label="IFSC Code"
                            name="IFSC"
                            maxLength={11}
                            externalValue={ifscCode}
                            required={true}
                            onChange={(e) => setIfscCode(e.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <BankTextInput
                            label="Bank Name"
                            name="BANK"
                            externalValue={bankDetails?.BANK}
                            required={true}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <BankTextInput
                            label="Branch Name"
                            name="BRANCH"
                            externalValue={bankDetails?.BRANCH}
                            required={true}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextInput
                            label="MICR Code"
                            name="micrCode"
                            maxLength={60}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <BankTextInput
                            label="Bank City"
                            name="bankDetails?.CENTRE"
                            externalValue={bankDetails?.CENTRE}
                            required={true}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <BankTextInput
                            label="Bank State"
                            name="bankDetails?.STATE}"
                            externalValue={bankDetails?.STATE}
                            required={true}
                          />
                        </Grid>
                      </>
                    )}
                  </>
                )}
              </>
              {(product?.INSURANCE_SLUG === "niva_bupa" ||
                product?.INSURANCE_SLUG === "aditya_birla_health" ||
                product?.INSURANCE_SLUG === "hdfc_ergo_optimasecure") && (
                <>
                  <Grid item xs={12}>
                    <YesNo
                      label="Are you a PEP - Politically Exposed Person ?"
                      name="PEP_QUESTION_1"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <YesNo label="Are you an NRI?" name="NRI_QUESTION_1" />
                  </Grid>
                </>
              )}
              {product?.INSURANCE_SLUG === "hdfc_ergo_optimasecure" && (
                <>
                  <Grid item xs={12}>
                    <FormControlLabel
                      label="Additionally, by ticking the check box we understand that you wish to have a physical copy of your policy."
                      control={<Checkbox />}
                      onChange={(e, checked) => {
                        setFieldValue("isPhysicalCopy", checked);
                      }}
                      checked={values.isPhysicalCopy}
                    />
                  </Grid>
                </>
              )}
              {product?.INSURANCE_SLUG === "niva_bupa" && (
                <>
                  <Grid item xs={12}>
                    <FormControlLabel
                      label={
                        <>
                          I/We authorize the Company to share information
                          pertaining to my / our proposal including the medical
                          records of the Insured / Proposer for the sole purpose
                          of Service Delivery with our empaneled provider.
                          <a
                            href="#"
                            onClick={handleOpen}
                            style={{
                              color: isRead ? "rgba(213, 158, 70,1)" : "blue",
                            }}
                          >
                            {" "}
                            Read more
                          </a>
                          <Typography
                            component="span"
                            color="error"
                            sx={{ marginLeft: "4px" }}
                          >
                            *
                          </Typography>
                        </>
                      }
                      control={<Checkbox />}
                      onChange={(e, checked) => {
                        setFieldValue("isConstentAgree", checked);
                      }}
                      checked={values.isConstentAgree}
                    />

                    {/* Modal for Consent Details */}
                    <Dialog
                      open={open}
                      onClose={handleClose}
                      maxWidth="sm"
                      fullWidth
                    >
                      <DialogTitle>
                        Consent Agreement
                        <IconButton
                          aria-label="close"
                          onClick={handleClose}
                          sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </DialogTitle>
                      <DialogContent dividers>
                        <Typography variant="body1" paragraph>
                          I, hereby provide my consent to Niva Bupa Health
                          Insurance Co. to share my personal information with
                          relevant third-party agencies for the purpose of
                          updating KYC details in my existing policies. I
                          understand and acknowledge that by providing consent:
                        </Typography>
                        <Typography variant="body2" paragraph>
                          a. I authorize Niva Bupa Health Insurance Company to
                          disclose the necessary personal information required
                          for updating KYC details.
                        </Typography>
                        <Typography variant="body2" paragraph>
                          b. I acknowledge that the information shared may
                          include my name, address, age, contact details,
                          identification documents and other information as
                          required under KYC compliance.
                        </Typography>
                        <Typography variant="body2" paragraph>
                          c. I understand that the purpose of sharing this
                          information is solely for updating KYC details in my
                          policies for the purpose of servicing. My personal
                          information shall be handled in accordance with
                          applicable data protection laws and regulations.
                        </Typography>
                        <Typography variant="body2" paragraph>
                          d. I hereby consent to Niva Bupa Health Insurance Co.
                          for the processing of my KYC record/information from
                          CKYCRR, for the purpose of KYC. My consent is given
                          freely, specifically for this purpose, and is
                          INFORMED, UNCONDITIONAL, and UNAMBIGOUS. By providing
                          this consent, I agree that the company may collect and
                          use only the necessary personal data required for the
                          specified purpose.
                        </Typography>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={handleClose}
                          color="primary"
                          variant="contained"
                        >
                          I Agree
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Grid>
                  {/* <Grid item xs={12}>
                    <FormControlLabel
                      label="I hereby agree to download kyc details"
                      control={<Checkbox />}
                      onChange={(e, checked) => {
                        setFieldValue("isAgree", checked);
                      }}
                      checked={values.isAgree}
                    />
                  </Grid> */}
                </>
              )}
              {product?.INSURANCE_SLUG === "manipal_cigna" && (
                <>
                  <Grid item xs={12}>
                    <FormControlLabel
                      label={
                        <Typography variant="body1">
                          I hereby consent to and authorize ManipalCigna Health
                          Insurance Company Limited (“Company”) and its
                          representatives to collect, use, share and disclose
                          information provided by me, as per the privacy policy
                          of the Company. Company or its representatives are
                          also hereby authorised to contact me (including
                          overriding my registry on NCPR/NDNC and/or under any
                          extant TRAI regulations) and / or notify about the
                          services being rendered by the Company
                          <span style={{ color: "red" }}> *</span>{" "}
                          {/* Required Icon */}
                        </Typography>
                      }
                      control={<Checkbox />}
                      onChange={(e, checked) => {
                        setFieldValue("isAgreeManipalCigna", checked);
                      }}
                      checked={values.isAgreeManipalCigna}
                    />
                  </Grid>
                </>
              )}
              <Grid
                item
                xs={12}
                justifyContent="center"
                display={"flex"}
                my={1}
              >
                <Box sx={{ position: "relative" }}>
                  <Button
                    size={isMobile ? "medium" : "large"}
                    variant="contained"
                    onClick={() => {
                      submitForm();
                    }}
                    disabled={isVerifyCKYC || isSaving}
                  >
                    {isSaving ? (
                      <CircularProgress size={"30px"} />
                    ) : (
                      <>
                        {isCKYC
                          ? "Proceed to CKYC Details"
                          : "Proceed to Insurer Details"}
                      </>
                    )}
                  </Button>
                  {isVerifyCKYC && (
                    <CircularProgress
                      size={isMobile ? 18 : 24}
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
// CSS for loading animation
const styles = `
@keyframes spin {
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
}
.loading-icon {
font-size: 24px; /* Adjust size as needed */
animation: spin 1s linear infinite;
}
.center {
display: flex;
justify-content: center;
align-items: center;
}
`;
// Inject styles into the document head
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);
export default ProposerForm;

export const bankAccountType = [
  // {
  //   OPTION_KEY: "Values",
  //   OPTION_VAL: "Values",
  // },
  {
    OPTION_KEY: "Savings",
    OPTION_VAL: "Savings",
  },
  {
    OPTION_KEY: "Current",
    OPTION_VAL: "Current",
  },
];
