import { Box, Skeleton, Typography } from "@mui/material";
import React from "react";
export interface QuoteDataProps {
  label: string;
  value: string | undefined | React.ReactNode;
  isLoading?: boolean;
  primary?: boolean;
  fullWidth?: boolean;
  isProposal?: boolean;
  required?:boolean;
}

const QuoteData: React.FC<QuoteDataProps> = ({
  primary,
  label,
  value,
  isLoading,
  fullWidth,
  isProposal,
  required = false
}) => {
  return (
    <>
      <Box
        display={"flex"}
        flexDirection='column'
        gap={{
          xs: isProposal ? "6px" : "0px",
          sm: "8px",
        }}
      >
        <Typography
          fontSize={{
            xs: isProposal ? "14px" : "10px",
            sm: isProposal ? "14px" : "12px",
          }}
          fontWeight={"400"}
        >
          {label}
          {required && <span style={{ color: "red" }}> *</span>}
        </Typography>
        {isLoading || !value ? (
          <Skeleton variant='text' width={"100%"} />
        ) : (
          <Typography
            fontSize={{ xs: "12px", sm: isProposal ? "15px" : "13px" }}
            lineHeight={"15px"}
            fontWeight={primary ? "600" : "500"}
            color={primary ? "primary.main" : "text.secondary"}
            sx={{
              wordBreak: "break-word",
            }}
          >
            {value}
          </Typography>
        )}
      </Box>
    </>
  );
};

export default QuoteData;
