import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Collapse,
  FormControlLabel,
  Grid,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import useUrlQuery from "../../../../hooks/useUrlQuery";
import {
  openViewPlanPopup,
  setShowMorePlans,
  updatePlansForCompare,
} from "../../../../modules/quotes.slice";
import { useInitiateProposalMuation } from "../../../../services/Proposal.service";
import { GetQuotesResponseInterface } from "../../../../services/Quotes.service";
import {
  commaSeparateNumber,
  showAmountInWords,
} from "../../../../utils/priceUtils";
import QuoteData from "./components/QuoteData";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEye";
interface QuoteCardProps {
  quoteData: GetQuotesResponseInterface | undefined;
  isLoading?: boolean;
  showMore?: boolean;
  no_of_more_plans?: number;
  index: number;
}
const QuoteCard: React.FC<QuoteCardProps> = ({
  quoteData,
  isLoading,
  showMore,
  no_of_more_plans,
  index,
  ...props
}) => {
  const { initiateProposal, isProposalInitiating } =
    useInitiateProposalMuation();
  const { getUrlQuery } = useUrlQuery();
  const quote_id = getUrlQuery("quote_id");
  const dispatch = useDispatch();
  const PRODUCT_DATA = quoteData?.PRODUCT_DATA;
  const PREMIUM_DATA = quoteData?.PREMIUM_DATA.PREMIUM_DATA;
  const PRODUCT_FEATURES =
    quoteData?.PRODUCT_FEATURES ||
    (Array(5) as GetQuotesResponseInterface["PRODUCT_FEATURES"]);
  const { showMorePlans, selected_plan_for_compare_product_ids } =
    useTypedSelector((state) => state.quote);
  const onBuyNowClick = () => {
    if (isProposalInitiating) return;
    initiateProposal({
      QUOTE_ID: quote_id,
      PRODUCT_ID: PRODUCT_DATA?.PRODUCTID,
      COMPANY_SLUG: PRODUCT_DATA?.INSURANCE_SLUG,
      TOTAL_PREMIUM: PREMIUM_DATA?.TOTAL_PREMIUM,
      NET_PREMIUM: PREMIUM_DATA?.NET_PREMIUM,
      SUM_INSURED: PREMIUM_DATA?.COVER_AMOUNT,
    });
  };
  const isMobile = useMediaQuery("(max-width:768px)");
  const isShowMoreSelected =
    showMorePlans[
      quoteData?.PRODUCT_DATA?.INSURANCE_SLUG?.split("_")
        .slice(0, 2)
        .join("_") || 0
    ];
  const onShowMoreClick = () => {
    dispatch(
      setShowMorePlans({
        key: PRODUCT_DATA?.INSURANCE_SLUG.split("_").slice(0, 2).join("_"),
        value: !isShowMoreSelected,
      })
    );
  };
  if (isMobile) {
    return (
      <>
        <Collapse
          in={(index > 0 && isShowMoreSelected) || !index}
          sx={{
            boxShadow: "0px 8px 20px 5px rgba(219, 219, 219, 0.25)",
          }}
        >
          <Grid
            container
            sx={{
              background: "#FFFFFF",
              borderRadius: "5px",
              p: 1,
              ml: 0,
              mt: 1,
              position: "relative",
            }}
            spacing={1}
            width="100%"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid
              item
              xs={2}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                "& img": {
                  maxWidth: "100%",
                  height: "30px",
                  width: "auto",
                },
              }}
            >
              {isLoading ? (
                <>
                  <Skeleton variant="rectangular" width={60} height={40} />
                  <Skeleton variant="text" width={60} height={20} />
                </>
              ) : (
                <>
                  <img src={PRODUCT_DATA?.LOGO_PATH} alt="" />{" "}
                  <Typography fontSize="10px" variant="caption">
                    {PRODUCT_DATA?.PRODUCT_NAME}
                  </Typography>
                </>
              )}
            </Grid>
            <Grid item xs={5}>
              <QuoteData
                label="Sum Assured"
                value={showAmountInWords(PREMIUM_DATA?.COVER_AMOUNT)}
                isLoading={isLoading}
              />
            </Grid>

            <Grid item xs={3}>
              {isLoading ? (
                <Skeleton variant="rectangular" width="100%" height="40px" />
              ) : (
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  size="small"
                  onClick={onBuyNowClick}
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "16px",
                    },
                  }}
                >
                  {isProposalInitiating ? (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: "white",
                      }}
                    />
                  ) : (
                    commaSeparateNumber(PREMIUM_DATA?.TOTAL_PREMIUM)
                  )}
                </Button>
              )}
            </Grid>
            {PRODUCT_FEATURES?.map((item) => {
              return (
                <Grid item xs={3}>
                  <QuoteData
                    label={item?.FEATURE_TITLE}
                    value={item?.USP_TEXT}
                    isLoading={isLoading}
                  />
                </Grid>
              );
            })}
            {showMore && !isLoading && (
              <Chip
                sx={{
                  position: "absolute",
                  fontSize: "12px",
                  left: "50%",
                  bottom: "0",
                  borderRadius: 0,
                  transform: "translateX(-50%)",
                }}
                onClick={onShowMoreClick}
                variant="filled"
                size="small"
                color="secondary"
                label={`Show ${no_of_more_plans} more plans`}
                icon={isShowMoreSelected ? <ExpandLess /> : <ExpandMore />}
              ></Chip>
            )}
          </Grid>
        </Collapse>
      </>
    );
  }
  return (
    <Collapse
      in={(index > 0 && isShowMoreSelected) || !index}
      sx={{
        boxShadow: "0px 8px 20px 5px rgba(219, 219, 219, 0.25)",
      }}
    >
      <Grid
        container
        sx={{
          background: "#FFFFFF",
          // boxShadow: "0px 8px 20px 5px rgba(219, 219, 219, 0.25)",
          borderRadius: "5px",
          p: 2,
          py: 4,
          pt: 1,
          ml: 0,
          mt: 1,
          position: "relative",
        }}
        spacing={2}
        width="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid
          item
          xs={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            "& img": {
              maxWidth: "100%",
              height: "40px",
              width: "auto",
            },
          }}
        >
          {isLoading ? (
            <>
              <Skeleton variant="rectangular" width={100} height={40} />
              <Skeleton variant="text" width={100} height={20} />
            </>
          ) : (
            <>
              <img src={PRODUCT_DATA?.LOGO_PATH} alt="" />{" "}
              <Typography variant="caption" fontSize={"10px"}>
                {PRODUCT_DATA?.INSURANCE_NAME}
              </Typography>
            </>
          )}
        </Grid>
        <Grid item xs={8}>
          <Box sx={{ fontSize: "18px", fontWeight: 500, marginBottom: "10px" }}>
            {PRODUCT_DATA?.PRODUCT_NAME}
          </Box>
          <Box display="flex" flexWrap="wrap" gap="6px">
            <Chip
              variant="outlined"
              sx={{
                borderRadius: "6px",
              }}
              label={showAmountInWords(PREMIUM_DATA?.COVER_AMOUNT)}
            ></Chip>
            {PRODUCT_FEATURES?.map((item) => {
              return (
                <Chip
                  sx={{
                    borderRadius: "6px",
                  }}
                  variant="outlined"
                  label={item?.USP_TEXT}
                />
              );
            })}
            <Chip
              icon={<RemoveRedEyeOutlinedIcon />}
              label="View All Features"
              color="primary"
              variant="outlined"
              onClick={() => {
                dispatch(
                  openViewPlanPopup({
                    product_id: PRODUCT_DATA?.PRODUCTID,
                    quote_data: {
                      logo: quoteData?.PRODUCT_DATA?.LOGO_PATH,
                      premium:
                        quoteData?.PREMIUM_DATA?.PREMIUM_DATA?.TOTAL_PREMIUM,
                      cover:
                        quoteData?.PREMIUM_DATA?.PREMIUM_DATA?.COVER_AMOUNT,
                    },
                  })
                );
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={2} height="100%">
          <Grid
            container
            columnSpacing={1}
            rowSpacing={1}
            height="100%"
            alignItems={"center"}
            justifyContent={"center"}
          >
            {/* <Grid
              item
              xs={12}
              gap={"10px"}
              display='flex'
              justifyContent={"center"}
            >
              <Grid item xs={6}>
                {isLoading ? (
                  <Skeleton variant='rectangular' width='100%' height='40px' />
                ) : (
                  <Button
                    variant='outlined'
                    fullWidth
                    color='secondary'
                    size='small'
                    sx={{
                      px: 0,
                      py: "2px",
                    }}
                  >
                    Add to Shortlist
                  </Button>
                )}
              </Grid>
              <Grid item xs={6}>
                {isLoading ? (
                  <Skeleton variant='rectangular' width='100%' height='40px' />
                ) : (
                  <Button
                    variant='outlined'
                    fullWidth
                    color='secondary'
                    size='small'
                    sx={{
                      px: 0,
                      py: "2px",
                    }}
                  >
                    Compare
                  </Button>
                )}
              </Grid>
            </Grid> */}
            <Grid
              item
              xs={12}
              gap={"10px"}
              display="flex"
              justifyContent={"center"}
            >
              {/* <Grid item xs={6}>
                {isLoading ? (
                  <Skeleton variant='rectangular' width='100%' height='40px' />
                ) : (
                  <Button
                    onClick={() => {
                      dispatch(
                        openViewPlanPopup({
                          product_id: PRODUCT_DATA?.PRODUCTID,
                          quote_data: {
                            logo: quoteData?.PRODUCT_DATA?.LOGO_PATH,
                            premium:
                              quoteData?.PREMIUM_DATA?.PREMIUM_DATA
                                ?.TOTAL_PREMIUM,
                            cover:
                              quoteData?.PREMIUM_DATA?.PREMIUM_DATA
                                ?.COVER_AMOUNT,
                          },
                        })
                      );
                    }}
                    variant='outlined'
                    fullWidth
                    color='primary'
                    size='medium'
                  >
                    View Plan
                  </Button>
                )}
              </Grid> */}
              <Grid item xs={12}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height="40px" />
                ) : (
                  <Stack>
                    <Button
                      variant="contained"
                      fullWidth
                      color="primary"
                      size="medium"
                      onClick={onBuyNowClick}
                    >
                      {isProposalInitiating ? (
                        <CircularProgress
                          size={30}
                          sx={{
                            color: "white",
                          }}
                        />
                      ) : (
                        commaSeparateNumber(PREMIUM_DATA?.TOTAL_PREMIUM)
                      )}
                    </Button>
                    <Box textAlign={"center"}>
                      <FormControlLabel
                        sx={{
                          mr: 0,
                          ml: 0,
                          position: "relative",
                          top: "20px",
                        }}
                        checked={selected_plan_for_compare_product_ids.some(
                          (item: any) => {
                            return (
                              item.product_id ===
                                quoteData?.PRODUCT_DATA.PRODUCTID &&
                              item.cover_amount ===
                                quoteData?.PREMIUM_DATA?.PREMIUM_DATA
                                  ?.COVER_AMOUNT
                            );
                          }
                        )}
                        control={
                          <Checkbox
                            sx={{
                              padding: 0,
                              mr: "6px",
                            }}
                          />
                        }
                        label="Compare"
                        onChange={(e, checked) => {
                          dispatch(
                            updatePlansForCompare({
                              cover_amount:
                                quoteData?.PREMIUM_DATA.PREMIUM_DATA
                                  .COVER_AMOUNT,
                              product_id: quoteData?.PRODUCT_DATA.PRODUCTID,
                              slug: quoteData?.PRODUCT_DATA.INSURANCE_SLUG,
                            })
                          );
                        }}
                      />
                    </Box>
                  </Stack>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {showMore && !isLoading && (
          <Chip
            sx={{
              position: "absolute",
              fontSize: "12px",
              left: "12px",
              bottom: "0",
              borderRadius: 0,
            }}
            onClick={onShowMoreClick}
            variant="filled"
            size="small"
            color="secondary"
            label={`Show ${no_of_more_plans} more plans`}
            icon={isShowMoreSelected ? <ExpandLess /> : <ExpandMore />}
          ></Chip>
        )}
      </Grid>
    </Collapse>
  );
};

export default QuoteCard;
