import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import api from "../api/api";
import { useTypedSelector } from "../hooks/useTypedSelector";
import useUrlQuery from "../hooks/useUrlQuery";
import { MembersInterface } from "../modules/landing.slice";
import { setActiveProposal, setProposalData } from "../modules/proposal.slice";
import { setSelectedRiders, setSelectedTenure } from "../modules/quotes.slice";
import { SelectOptions } from "../pages/ProposalPage/components/InputComponents/Select";
import { RiderInterface } from "./Quotes.service";
interface InitiateProposalRequest {
  PRODUCT_ID?: number;
  COMPANY_SLUG?: string;
  QUOTE_ID?: string;
  TOTAL_PREMIUM?: string;
  SUM_INSURED?: string;
  ADD_ONS?: RiderInterface[];
  TENURE?: string;
  NET_PREMIUM?: string;
  PORT_POLICY?: boolean;
  selected_members?: any;
  BLOCK_PORTING?: any;
  port_flag?: boolean;
}
interface InitiateProposalResponse {
  status: 200 | 500;
  response: {
    PROPOSAL_ID: string;
  };
}
export const useInitiateProposalMuation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { mutate, isLoading } = useMutation(
    (req: InitiateProposalRequest) => {
      return api.post<InitiateProposalResponse>(
        "api/InitiateHealthProposal",
        req
      );
    },
    {
      onSuccess: (res) => {
        dispatch(setSelectedRiders([]));
        navigate({
          pathname: "/product-summary",
          search: `?proposal_id=${res.data.response.PROPOSAL_ID}`,
        });
      },
    }
  );
  return {
    initiateProposal: mutate,
    isProposalInitiating: isLoading,
  };
};
interface ProposalDetailsResponse {
  status: 200 | 500;
  data: {
    PROPOSAL: {
      _id: string;
      QUOTE_ID: string;
      PRODUCT_ID: number;
      COMPANY_SLUG: string;
      TOTAL_PREMIUM: string;
      SUM_INSURED: string;
      updated_at: string;
      created_at: string;
      TENURE: string;
      ADD_ONS: RiderInterface[];
      FORMDATA: any;
      PORT_POLICY?: boolean;
      NET_PREMIUM: string;
      BLOCK_PORTING?: boolean;
      ISPORTING?: boolean;
    };
    QUOTE_REQUEST: {
      _id: string;
      pincode: string;
      mobile_no: string;
      members: MembersInterface[];
      policy_type: "I" | "F";
      requested_si: string;
      updated_at: string;
      created_at: string;
      proposer_gender: string;
      BLOCK_PORTING?: boolean;
    };
    product_data: {
      PRODUCTID: number;
      IC_ID: string;
      ADULT_MIN: string;
      ADULT_MAX: string;
      LOB: string;
      POLICY_TYPE: string;
      PRODUCT_TYPE: string;
      PRODUCT_NAME: string;
      PRODUCT_IDENTIFIER: string;
      CREATED_DATE: string;
      UPDATED_DATE: string;
      INSURANCE_NAME: string;
      INSURANCE_SLUG: string;
      ADDRESS: string;
      CONTACT_DETAILS: string;
      BROCHURE_LINK: string;
      LOGO_PATH: string;
      CHILD_MIN: number;
      CHILD_MAX: number;
    };
  };
}
export const useGetProposalDetailsQuery = () => {
  const { getUrlQuery } = useUrlQuery();
  const proposal_id = getUrlQuery("proposal_id");
  const dispatch = useDispatch();
  const { data, isLoading, isFetching } = useQuery(
    ["proposal", proposal_id],
    () =>
      api.post<ProposalDetailsResponse>("/api/GetHealthProposalDetails", {
        PROPOSAL_ID: proposal_id,
      }),
    {
      onSuccess: (data) => {
        if (data.data.data.PROPOSAL.ADD_ONS) {
          dispatch(setSelectedRiders(data.data.data.PROPOSAL.ADD_ONS));
          dispatch(setSelectedTenure(data.data.data.PROPOSAL.TENURE));
        }
        if (data.data.data.PROPOSAL.FORMDATA) {
          dispatch(setProposalData(data.data.data.PROPOSAL.FORMDATA));
        }
      },
    }
  );
  return {
    members: data?.data.data.QUOTE_REQUEST.members,
    enquiry: data?.data.data.QUOTE_REQUEST,
    quote_data: data?.data.data.PROPOSAL,
    product: data?.data.data.product_data,
    isCKYC: ["star", "manipal_cigna", "care"].includes(
      data?.data.data.product_data?.INSURANCE_SLUG || ""
    ),
    isPortability: data?.data.data.PROPOSAL.PORT_POLICY,
    isProposalLoading: isLoading || isFetching,
  };
};

export interface ProposalQuestions {
  ID: number;
  QUESTION_TITLE: string;
  QUESTION_ALIAS: string;
  IC_ID: 11;
  ANSWER_TYPE:
    | "BOOLEAN"
    | "DROPDOWN"
    | "FREE_TEXT"
    | "TOGGLE"
    | "DATE"
    | "MONTH_YEAR";
  PARENT_ID: string | null;
  PARENT_KEY: string | null;
  VALUE: SelectOptions[];
  RESTRICT_KEY: string | null;
  RESTRICT_OPERATOR: string | null;
  HAS_CHILD: "Y" | "N";
  CREATED_AT: string;
  UPDATED_AT: string;
  ADDITIONAL?: ProposalQuestions[];
  VIEWS?: Array<"year" | "month" | "day">;
  TEXTMASK?: string;
  MAXLENGTH?: number;
  ALLOW_ONLY_FUTURE?: "0" | "1";
  allow_multiple?: boolean;
}
interface ProposalQuestionsResponse {
  data: ProposalQuestions[];
  status: 200 | 500;
}
export const useGetProposalQuestions = () => {
  const { product } = useGetProposalDetailsQuery();
  const { getUrlQuery } = useUrlQuery();
  const PROPOSAL_ID = getUrlQuery("proposal_id");
  const { data } = useQuery(
    ["question", product?.IC_ID],
    () =>
      api.post<ProposalQuestionsResponse>("/api/GetHealthQADetails", {
        IC_ID: product?.IC_ID,
        PRODUCT_ID: product?.PRODUCTID,
        proposal_id: PROPOSAL_ID,
      }),
    {
      enabled: !!product?.IC_ID,
    }
  );

  return {
    questions: data?.data?.data,
  };
};

export const useUpdateProposalMutation = () => {
  const { getUrlQuery } = useUrlQuery();
  const proposal_id = getUrlQuery("proposal_id");
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    (req: InitiateProposalRequest) =>
      api.post("api/UpdateHealthProposal", {
        ...req,
        PROPOSAL_ID: proposal_id,
      }),
    {
      onSuccess: (res) => {
        navigate({
          pathname: "/proposal",
          search: `?proposal_id=${proposal_id}`,
        });
        queryClient.removeQueries(["proposal"]);
      },
    }
  );

  return {
    updateProposal: mutate,
  };
};

export const useUpdateProposalFormData = (onSuccess = () => {}) => {
  const { getUrlQuery } = useUrlQuery();
  const proposal_id = getUrlQuery("proposal_id");
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { proposalData, activeProposal } = useTypedSelector(
    (state) => state.proposal
  );
  const { mutate, isLoading, isSuccess, mutateAsync } = useMutation(
    async (req: any) => {
      const response = await api.post("api/UpdateRawHealthProposal", {
        PROPOSAL_ID: proposal_id,
        FORMDATA: {
          ...proposalData,
          ...req,
        },
      });
      if (response?.status === 500) {
        if (response?.data?.showpopup) {
          Swal.fire("Alert", response?.data?.message);
        }
      }
      return response;
    },
    {
      onSuccess: async () => {
        await api.post("api/RecalculateHealthPremium", {
          proposal_id,
        });
        queryClient.removeQueries(["proposal"]);
        dispatch(setActiveProposal(activeProposal + 1));
        onSuccess && onSuccess();
      },
      onError: (err: any) => {
        if (err?.response?.data?.status === 500) {
          if (err?.response?.data?.showpopup) {
            Swal.fire("Alert", err?.response?.data?.message);
          } else {
            toast.error(
              err?.response?.data?.message ||
                "Something went wrong while submitting the proposal"
            );
          }
        }
      },
    }
  );
  return {
    saveForm: mutate,
    isSaving: isLoading,
    isSaved: isSuccess,
    asyncSaveForm: mutateAsync,
  };
};
interface PincodeRequest {
  pincode: string;
  company_alias?: string;
}
interface PincodeResponse {
  status: 200 | 500;
  response: {
    city: SelectOptions[];
    state: [SelectOptions];
  };
}
interface MasterResponse {
  status: 200 | 500;
  response: SelectOptions[];
}
export const useGetPincodeMutation = () => {
  const { isLoading, data, isError, mutate, isSuccess } = useMutation(
    ({ pincode, company_alias }: PincodeRequest) => {
      return api.post<PincodeResponse>("api/GetPincodeMaster", {
        pincode,
        company_alias,
      });
    },
    {
      onSettled: (data) => {
        if (data?.data.status === 500) {
          toast.error("Pincode not found");
        }
      },
    }
  );
  return {
    isCityStateLoading: isLoading,
    getCityState: mutate,
    cityOptions: data?.data?.response?.city,
    stateOptions: data?.data?.response?.state,
    isError,
    isCityStateFetched: isSuccess,
  };
};
export const useGetRelationMasterQuery = () => {
  const { quote_data } = useGetProposalDetailsQuery();
  const { isLoading, data } = useQuery(
    ["relation", quote_data?.COMPANY_SLUG],
    () => {
      return api.post<MasterResponse>("api/GetRelationMaster", {
        LOB: "HEALTH",
        COMPANY_SLUG: quote_data?.COMPANY_SLUG,
      });
    }
  );

  return {
    relations: data?.data?.response,
    isRelationsLoading: isLoading,
  };
};
export const useGetOccupationQuery = () => {
  const { quote_data } = useGetProposalDetailsQuery();
  const { isLoading, data } = useQuery(
    ["occupation", quote_data?.COMPANY_SLUG],
    () => {
      return api.post<MasterResponse>("api/GetOccupationMaster", {
        COMPANY_SLUG: quote_data?.COMPANY_SLUG,
        LOB: "HEALTH",
      });
    }
  );
  return {
    isOccupationLoading: isLoading,
    occupation_list: data?.data.response,
  };
};

export const serialize = function (obj: any) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
};
export const useProposalFinalSubmitMutation = () => {
  const { getUrlQuery } = useUrlQuery();
  const proposal_id = getUrlQuery("proposal_id");
  const navigate = useNavigate();
  const { quote_data } = useGetProposalDetailsQuery();
  const { initiatePayment } = usePaymentQuery();
  const { refetch: fetchHdfcDocument } = useGetHdfcDocument();
  const { isLoading, mutate, data } = useMutation(
    () => {
      return api.post("api/HealthProposalFinalSubmit", {
        PROPOSAL_ID: proposal_id,
      });
    },

    {
      onSuccess: (data) => {
        if (data?.data?.status === 500) {
          toast.error(data?.data?.message);
        }
        if (quote_data?.COMPANY_SLUG === "hdfc_ergo_optimasecure") {
          if (data?.data?.status === 200 || data?.status === 200) {
            fetchHdfcDocument();
          }
        }
        if (quote_data?.COMPANY_SLUG !== "hdfc_ergo_optimasecure") {
          if (data?.data?.status === 200 || data?.status === 200) {
            initiatePayment();
          }
        }
        if (quote_data?.COMPANY_SLUG !== "hdfc_ergo_optimasecure") {
          if (data?.data?.uw_data?.uw_flag) {
            Swal.fire(
              "NSTP",
              "This proposal will be sent for doctor approval. The policy will be converted only after approval."
            );
          }
        }
        if (data?.data?.showpopup) {
          Swal.fire("Alert", data?.data?.message);
        }
      },
      onError: (err: any) => {
        toast.error(
          err?.response?.data?.message ||
            "Something went wrong while submitting the proposal"
        );
      },
    }
  );
  return {
    isSubmitting: isLoading,
    submitProposal: mutate,
    data: data?.data,
    success: data?.data?.status,
  };
};
interface IOptions {
  onCKYCIncomplete?: () => void;
}
export const usePaymentQuery = (options?: IOptions) => {
  const { getUrlQuery } = useUrlQuery();
  const proposal_id = getUrlQuery("proposal_id");
  const navigate = useNavigate();
  const { isLoading, data, mutate } = useMutation(
    () => {
      return api.post("api/GetHealthPaymentDetails", {
        PROPOSAL_ID: proposal_id,
      });
    },
    {
      onSuccess: (data) => {
        if (data.data.status === 500) {
          toast.error(data.data.message);
        }
        if (data.data.status === 200) {
          const { PG_FORM, CKYC_LINK } = data.data;
          if (CKYC_LINK) {
            window.open(CKYC_LINK, "_blank");
            options?.onCKYCIncomplete && options?.onCKYCIncomplete();
            return;
          }
          const { fields, method, url, key } = PG_FORM;
          if (method === "RAZORPAY") {
            const options = url;

            // Check if Razorpay is available
            // @ts-ignore
            const rzp1 = window.Razorpay(options);
            rzp1.open();
          }
          if (method === "POST") {
            const form = document.createElement("form");
            form.name = "PAYMENTFORM";
            Object.keys(fields).forEach((item) => {
              const input = document.createElement("input");
              input.name = item;
              input.value = fields[item];
              form.appendChild(input);
            });
            form.action = url;
            form.method = method;
            document.body.appendChild(form);
            form.submit();
            document.body.removeChild(form);
          } else {
            let query: any;
            if (fields) {
              query = serialize(fields);
            }
            if (query) {
              window.open(url + "?" + query, "_self");
            } else window.open(url, "_self");
          }
        }
      },
    }
  );
  return {
    paymentData: data?.data,
    isPaymentLoading: isLoading,
    initiatePayment: mutate,
  };
};

export const useGetCouncilMasterQuery = () => {
  const { quote_data } = useGetProposalDetailsQuery();
  const { isLoading, data } = useQuery(
    ["medical_council", quote_data?.COMPANY_SLUG],
    () => {
      return api.post<any>("api/GetMedicalCouncilMaster", {
        LOB: "HEALTH",
        COMPANY_SLUG: quote_data?.COMPANY_SLUG,
      });
    }
  );

  return {
    council_names: data?.data?.response?.map((item: any) => ({
      OPTION_KEY: item?.COUNCIL_NAME,
      OPTION_VAL: item?.COUNCIL_NAME,
    })),
    isCouncilNamesLoading: isLoading,
  };
};

interface BankDetails {
  bankAccountNo: any;
  BANK: string;
  BRANCH: string;
  CENTRE: string;
  STATE: string;
  IFSC: string;
  bankAccountType: any;
  micrCode: string;
  bankName: string;
}

const fetchBankDetailsByIFSC = async (
  ifscCode: string,
  proposal_id: string
): Promise<BankDetails | null> => {
  if (!ifscCode) return null;
  try {
    const response = await api.get("/api/GetBankDetailsByIFSC", {
      params: {
        ifscCode,
        PROPOSAL_ID: proposal_id, // Adding the proposal_id to the params
      },
    });
    return response?.data?.response;
  } catch (error) {
    console.error("Error fetching bank details:", error);
    throw new Error("Failed to fetch bank details");
  }
};

export const useGetBankDetailsByIFSC = () => {
  const { getUrlQuery } = useUrlQuery();
  const proposal_id = getUrlQuery("proposal_id");

  const mutation = useMutation(
    (ifscCode: string) => fetchBankDetailsByIFSC(ifscCode, proposal_id),
    {
      onSuccess: (data) => {
        // Handle success (optional)
      },
      onError: (error) => {
        console.error("Error fetching bank details:", error);
      },
    }
  );

  return mutation;
};

export const useUploadPortingFile = () => {
  const upload = async (formData: any) => {
    try {
      const response = await api.post("/api/uploadFile", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  };

  return { upload };
};

export const useGetHdfcDocument = () => {
  const { getUrlQuery } = useUrlQuery();
  const proposal_id = getUrlQuery("proposal_id");

  // Function to fetch HDFC Document
  const fetchHdfcDocument = async () => {
    const response = await api.get("/api/HealthHDFCCISDocument", {
      params: { proposal_id },
    });
    return response.data; // Return only the data part of the response
  };

  const { data, isError, error, refetch, isLoading } = useQuery(
    ["hdfcDocument", proposal_id],
    fetchHdfcDocument,
    {
      enabled: false, // Disable automatic fetching
      onSuccess: (data) => {
        // Check for status 500 and show a popup if needed
        if (data?.status === 500) {
          toast.error(data?.message);
        }
      },
    }
  );

  return {
    message: data?.message, // The response message
    error,
    refetch,
    isLoading,
    hdfcDocument: data, // Expose refetch to manually trigger the query
  };
};

export const useVerifyDocumentOtp = () => {
  const { getUrlQuery } = useUrlQuery();
  const { initiatePayment } = usePaymentQuery();
  const proposal_id = getUrlQuery("proposal_id");

  const verifyDocumentOTP = async (otp: string) => {
    const response = await api.post("/api/VerifyDocumentOTP", {
      proposal_id, // Pass the proposal_id and otp in the payload
      otp,
    });
    return response; // Return the response for further handling
  };

  const handleVerifyOtp = async (otp: string) => {
    try {
      const otpResponse = await verifyDocumentOTP(otp);
      if (otpResponse?.status === 200) {
        initiatePayment();
        return otpResponse;
      }

      // Check if the response contains status 400 for invalid OTP
      if (otpResponse?.data?.status === 400) {
        return otpResponse; // Return the response with status 400 for further handling
      }
    } catch (error) {
      console.error("OTP verification failed", error);
      throw new Error(
        "An error occurred while verifying OTP. Please try again."
      );
    }
  };

  return {
    handleVerifyOtp, // Return the updated function
  };
};

export const useGetHdfcBankNames = (query: string | any[]) => {
  // Function to fetch bank names from the API
  const fetchBankNames = async () => {
    const response = await api.get(`api/GetHDFCBankNames`, {
      params: { bank_name: query },
    });
    return response.data;
  };

  const { data, isError, error, refetch, isLoading } = useQuery(
    ["hdfcBankNames", query], // Query key includes the query for caching
    fetchBankNames,
    {
      enabled: !!query && query.length >= 1, // Fetch only if the query is defined and has at least 2 characters
      onSuccess: (data) => {
        // Handle success if needed
        if (data?.status === 500) {
          toast.error(data?.message); // Show error message if status is 500
        }
      },
      onError: (error) => {
        // Handle other errors
        toast.error(`Error: message}`);
      },
    }
  );

  return {
    bankNames: data || [], // Return fetched bank names or an empty array
    errors: error,
    refetch, // Expose refetch to manually trigger the query
    isLoadings: isLoading, // Loading state
  };
};
