import * as yup from "yup";

export const starInitialData = {
  portability_reason: "",
  members_covered_in_existing: {
    members: [],
  },
};
const validation = (members) =>
  yup.object().shape({
    portability_reason: yup.string().required("This field is required."),
    other_reason: yup.string().when("portability_reason", {
      is: "other",
      then: yup.string().required("This field is required."),
    }),
    members_covered_in_existing: yup.object().shape({
      members: yup.array().required("This field is required").of(yup.string()),
    }),
    member_data: yup.lazy((value) => {
      const keys = Object.keys(value || {});
      const innerValidation = keys.reduce(
        (prev, curr) => ({
          ...prev,
          [curr]: yup.object().shape({
            previous_policy_type: yup
              .string()
              .required("This field is required."),
            insurer_name: yup.string().required("This field is required."),
            product_name: yup.string().required("This field is required."),
            policy_type: yup.string().required("This field is required."),
            policy_inception_date: yup
              .string()
              .required("This field is required."),
            continous_coverage_year: yup
              .string()
              .required("This field is required.")
              .test(
                "check-policy-details",
                "Please fill all the coverage details.",
                function (val) {
                  const policy_details_length =
                    this.parent?.policy_details?.length;

                  return policy_details_length === +val;
                }
              ),
            policy_details: yup.lazy(function (value) {
              return yup.array().of(
                yup.object().shape({
                  policy_no: yup.string().required("This field is required."),
                  policy_cust_id: yup
                    .string()
                    .required("This field is required."),
                  policy_from_date: yup
                    .string()
                    .required("This field is required."),
                  policy_to_date: yup
                    .string()
                    .required("This field is required."),
                  sum_insured: yup.string().required("This field is required."),
                  cumulative_bonus: yup
                    .string()
                    .required("This field is required."),
                  is_claim_made: yup
                    .string()
                    .required("This field is required."),
                  no_of_claims: yup.string().when("is_claim_made", {
                    is: "Y",
                    then: yup.string().required("This field is required."),
                  }),
                  claim_amount: yup.string().when("is_claim_made", {
                    is: "Y",
                    then: yup.string().required("This field is required."),
                  }),
                  illness_claim: yup.string().when("is_claim_made", {
                    is: "Y",
                    then: yup.string().required("This field is required."),
                  }),
                })
              );
            }),
          }),
        }),
        {}
      );
      return yup.object().shape({
        ...innerValidation,
      });
    }),
  });

export default validation;
