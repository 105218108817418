export const previous_insure_company=[{
    OPTION_KEY: "616319",
    OPTION_VAL: "SBI General Insurance Company Ltd.",
},{
    OPTION_KEY: "616329",
    OPTION_VAL: "Magma HDI General Insurance Co Ltd",
},{
    OPTION_KEY: "616318",
    OPTION_VAL: "Religare Health Insurance Company Limited",
},{
    OPTION_KEY: "616317",
    OPTION_VAL: "MAX BUPA HEALTH INSURANCE",
},{
    OPTION_KEY: "616325",
    OPTION_VAL: "GUJRAT GOVERNMENT INSURANCE FUND",
},{
    OPTION_KEY: "616316",
    OPTION_VAL: "L& General Insurance Company Limited",
},{
    OPTION_KEY: "616322",
    OPTION_VAL: "EXPORT CREDIT GUARANTEE CORPORATION OF INDIA LTD",
},{
    OPTION_KEY: "616320",
    OPTION_VAL: "UNIVERSAL SOMPO GENERAL INSURANCE CO.LTD.",
},{
    OPTION_KEY: "616301",
    OPTION_VAL: "BAJAJ ALLIANZ GENERAL INSURANCE CO.LTD",
},{
    OPTION_KEY: "616304",
    OPTION_VAL: "ICICI LOMBARD GENERAL INSURANCE CO. LTD",
},{
    OPTION_KEY: "616305",
    OPTION_VAL: "IFFCO TOKIO GENERAL INSURANCE CO. LTD",
},{
    OPTION_KEY: "616308",
    OPTION_VAL: "THE ORIENTAL INSURANCE CO. LTD",
},{
    OPTION_KEY: "616309",
    OPTION_VAL: "RELIANCE GENERAL INSURANCE CO.LTD",
},{
    OPTION_KEY: "616310",
    OPTION_VAL: "ROYAL SUNDARAM ALLIANCE INSURANCE CO.LTD",
},{
    OPTION_KEY: "616312",
    OPTION_VAL: "TATA AIG GENERAL INSURANCE CO.LTD",
},{
    OPTION_KEY: "616313",
    OPTION_VAL: "UNITED INDIA INSURANCE CO.LTD",
},{
    OPTION_KEY: "616302",
    OPTION_VAL: "CHOLAMANDALAM MS GENERAL INSURANCE CO.LTD.",
},{
    OPTION_KEY: "616303",
    OPTION_VAL: "HDFC ERGO GENERAL INSURANCE CO.LTD",
},{
    OPTION_KEY: "616311",
    OPTION_VAL: "STAR HEALTH AND ALLIED INSURANCE COMPANY LIMITED",
},{
    OPTION_KEY: "AMHI",
    OPTION_VAL: "APOLLO MUNICH HEALTH INSURANCE COMPANY LIMITED",
},{
    OPTION_KEY: "616315",
    OPTION_VAL: "FUTURE GENERALI INDIA INSURANCE COMPANY LIMITED-21",
},{
    OPTION_KEY: "616324",
    OPTION_VAL: "SHRIRAM GENERAL INSURANCE COMPANY LIMITED",
},{
    OPTION_KEY: "616314",
    OPTION_VAL: "BHARTI AXA GENERAL INSURANCE COMPANY LIMITED",
},{
    OPTION_KEY: "616323",
    OPTION_VAL: "RAHEJA QBE GENERAL INSURANCE COMPANY LIMITED	",
},{
    OPTION_KEY: "616306",
    OPTION_VAL: "NATIONAL INSURANCE CO.LTD.",
},{
    OPTION_KEY: "616307",
    OPTION_VAL: "THE NEW INDIA ASSURANCE CO. LTD.",
},{
    OPTION_KEY: "616321",
    OPTION_VAL: "AGRICULTURE INSURANCE CO. OF INDIA LTD.",
},{
    OPTION_KEY: "616326",
    OPTION_VAL: "Liberty Videocon General Insurance Company Limited",
},{
    OPTION_KEY: "ADKV",
    OPTION_VAL: "APOLLO MUNICH HEALTH INSURANCE COMPANY LIMITED",
}]