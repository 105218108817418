import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import ShadowBox from "../ShadowBox/ShadowBox";
const PairData: React.FC<{
  primary?: boolean;
  label: string;
  value: string | undefined;
}> = ({ label, value, primary }) => {
  const theme = useTheme();
  return (
    <>
      <ShadowBox
        sx={{
          background: primary ? theme.palette.primary.light : "",
        }}
        px={1}
        py={primary ? 2 : 1}
        br
      >
        <Box
          display='flex'
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography
            fontSize={primary ? "16px" : "14px"}
            fontWeight='500'
            color={primary ? "text.secondary" : "text.primary"}
          >
            {label}
          </Typography>
          <Typography
            fontSize={{
              xs: "14px",
              md: "18px",
            }}
            color={"primary.main"}
            fontWeight='600'
          >
            {value}
          </Typography>
        </Box>
      </ShadowBox>
    </>
  );
};
export const PairDataWithoutBox: React.FC<{
  label: string;
  value: string | undefined;
}> = ({ label, value }) => {
  return (
    <>
      <Box display='flex' justifyContent={"space-between"} alignItems='center'>
        <Typography
          fontSize={{
            xs: "12px",
            md: "14px",
          }}
          fontWeight='500'
          sx={{
            width: "70%",
          }}
        >
          {label}
        </Typography>
        <Typography
          sx={{
            width: "30%",
          }}
          align={"right"}
          fontSize={{
            xs: "14px",
            md: "18px",
          }}
          color={"grey"}
          fontWeight='600'
        >
          {value}
        </Typography>
      </Box>
    </>
  );
};

export default PairData;
