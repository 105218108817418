import { Add, Remove } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import {
  addOrRemoveRider,
  setSelectedMembers,
  updateRiderSumInsured,
} from "../../../../modules/quotes.slice";

import {
  RiderInterface,
  useGetRiders,
} from "../../../../services/Quotes.service";
import Select from "../../../ProposalPage/components/InputComponents/Select";
import RiderSelect from "./RiderSelect";
import { useGetProposalDetailsQuery } from "../../../../services/Proposal.service";

interface RiderCardProps {
  rider: RiderInterface;
  isDisabled?: boolean;
  isFetching?: boolean;
  refetchRiders: () => any;
  isEnabled: boolean;
}

const RiderCard: React.FC<RiderCardProps> = ({
  rider,
  isDisabled,
  isFetching,
  refetchRiders,
  isEnabled,
}) => {
  const dispatch = useDispatch();
  const { selectedRiders, selected_members_rider } = useTypedSelector(
    (state) => state.quote
  );

  const isRiderSelected = selectedRiders.some(
    (item) => item.ADD_ON_ID === rider.ADD_ON_ID
  );
  const { members, product } = useGetProposalDetailsQuery();
  const sum_insured = selectedRiders.find(
    (item) => item.ADD_ON_ID === rider.ADD_ON_ID
  )?.SUM_INSURED;

  const onClick = (selectedMembers?: string[]) => {
    if (isFetching) return null;
    dispatch(
      addOrRemoveRider({
        ...rider,
        selectedMembers: selectedMembers || [],
      })
    );
  };

  useEffect(() => {
    if (rider.IS_MEMBER_LEVEL === "Y") {
      if (!isRiderSelected && selected_members_rider[rider.ADD_ON_ID]?.length) {
        // onClick();
      } else if (
        isRiderSelected &&
        !selected_members_rider[rider.ADD_ON_ID]?.length
      ) {
        onClick();
      }
    }
  }, [selected_members_rider[rider.ADD_ON_ID]?.length, isRiderSelected, rider]);

  // useEffect(() => {
  //   if (product?.INSURANCE_SLUG === "care") {
  //     if (rider.IS_MANDETORY === "Y" && !isRiderSelected) {
  //       onClick();
  //     }
  //   }
  // }, [rider.IS_MANDETORY, isRiderSelected]);

  return (
    <>
      <Box
        width="100%"
        py={2}
        px={1}
        sx={{
          border: "1px solid #e0e0e0",
          borderRadius: "5px",
        }}
      >
        <Grid container gap={0.5}>
          <Grid item xs={9}>
            <Typography
              fontSize={{
                xs: "15px",
                sm: "18px",
              }}
              color="text.secondary"
              gutterBottom
            >
              {rider.ADD_ON_NAME}
            </Typography>
            <Typography
              fontSize={{
                xs: "11px",
                sm: "14px",
              }}
            >
              {rider.ADDON_DESCRIPTION}
            </Typography>
            {rider.IS_MEMBER_LEVEL === "Y" && (
              <>
                <Grid container spacing={2} mt={1}>
                  {members?.map((member, index) => {
                    const key_name = member.count
                      ? member.relation + member.count
                      : member.relation;
                    return (
                      <>
                        <Grid item xs={3}>
                          <input
                            className="custom_checkbox"
                            type="checkbox"
                            id={`${key_name}${rider.ADD_ON_ID}`.replace(
                              /\./g,
                              ""
                            )}
                            name={key_name}
                            value={key_name}
                            checked={selected_members_rider[
                              rider.ADD_ON_ID
                            ]?.includes(key_name)}
                            onChange={(e) => {
                              if (e.target.checked) {
                                dispatch(
                                  setSelectedMembers({
                                    ...selected_members_rider,
                                    [rider.ADD_ON_ID]: [
                                      ...(selected_members_rider[
                                        rider.ADD_ON_ID
                                      ] || []),
                                      key_name,
                                    ],
                                  })
                                );
                                if (!isRiderSelected) {
                                  onClick([
                                    ...(selected_members_rider[
                                      rider.ADD_ON_ID
                                    ] || []),
                                    key_name,
                                  ]);
                                }
                              } else {
                                dispatch(
                                  setSelectedMembers({
                                    ...selected_members_rider,
                                    [rider.ADD_ON_ID]: [
                                      ...(
                                        selected_members_rider[
                                          rider.ADD_ON_ID
                                        ] || []
                                      ).filter((item) => item !== key_name),
                                    ],
                                  })
                                );
                                if (
                                  isRiderSelected &&
                                  !(
                                    selected_members_rider[rider.ADD_ON_ID] ||
                                    []
                                  ).filter((item) => item !== key_name).length
                                ) {
                                  onClick(
                                    (
                                      selected_members_rider[rider.ADD_ON_ID] ||
                                      []
                                    ).filter((item) => item !== key_name)
                                  );
                                }
                              }
                            }}
                          />
                          <label
                            className="custom_checkbox_label"
                            htmlFor={`${key_name}${rider.ADD_ON_ID}`.replace(
                              /\./g,
                              ""
                            )}
                          >
                            {key_name}
                          </label>
                        </Grid>
                      </>
                    );
                  })}
                </Grid>
              </>
            )}
            {rider.VALUE && (
              <RiderSelect
                options={
                  rider.ADD_ON_SLUG === "PEDWP1Y1155"
                    ? [
                        { code: "0", display_name: "Select" },
                        ...rider?.VALUE?.split(",").map((item, index) => {
                          const display_names =
                            rider?.additionalOptions?.[0]?.options?.split(
                              ","
                            ) || [];
                          return {
                            code: item,
                            display_name: display_names[index],
                          };
                        }),
                      ]
                    : rider?.VALUE?.split(",").map((item, index) => {
                        return {
                          code: item.trim(),
                          display_name: item.trim(),
                        };
                      })
                }
                id={rider.ADD_ON_SLUG}
                label={rider.additionalOptions?.[0].label}
                value={sum_insured || rider?.VALUE?.split(",")[0]}
                handleChange={(e) => {
                  dispatch(
                    updateRiderSumInsured({
                      ADD_ON_ID: rider.ADD_ON_ID,
                      SUM_INSURED: e.target.value,
                      rider,
                    })
                  );
                }}
              />
            )}
          </Grid>
          <Grid item xs={2.5}>
            <Button
              variant="outlined"
              color={"primary"}
              sx={{
                fontWeight: "500",
                width: "100%",
                justifyContent: isFetching ? "center" : "flex-start",
                background: isRiderSelected ? "#D3ECF1" : "",
                fontSize: {
                  xs: "12px",
                  sm: "15px",
                },
              }}
              disabled={rider.IS_MANDETORY === "Y" || isDisabled || !isEnabled}
              onClick={() => {
                onClick(selected_members_rider[rider.ADD_ON_ID]);
              }}
            >
              {isFetching ? (
                <CircularProgress />
              ) : (
                <>
                  <Checkbox checked={isRiderSelected} />
                  {!!Number(rider.premium) &&
                    Number(rider.premium).toLocaleString("en-IN")}
                </>
              )}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default RiderCard;
